import {FETCH_HADITH, FETCH_HADITH_CHAPTER, FETCH_HADITH_CHAPTER_CONTENT} from "../index";
import http from "../../apis/service";
import {hadith, hadithChapter, hadithChapterContent} from "../../apis/endPoint";

export const getHadith = (page, pageSize) => async dispatch => {
    const response = await http.get(`${hadith}?page=${page}&pageSize=${pageSize}`);
    dispatch({
        type: FETCH_HADITH,
        payload: response
    });

};

export const getHadithChapter = (id, page, pageSize) => async dispatch => {
    const response = await http.get(`${hadithChapter}/${id}?page=${page}&pageSize=${pageSize}`);
    dispatch({
        type: FETCH_HADITH_CHAPTER,
        payload: response
    });

};
export const getHadithChapterContent = (id, page, pageSize) => async dispatch => {
    const response = await http.get(`${hadithChapterContent}/${id}?page=${page}&pageSize=${pageSize}`);
    dispatch({
        type: FETCH_HADITH_CHAPTER_CONTENT,
        payload: response
    });
};