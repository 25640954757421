import React, {Component} from 'react';
import Course_material from "../../assets/static/svg/Course_material.svg";
import Digital_Quran from "../../assets/static/svg/Digital_Quran.svg";
import Health_learning from "../../assets/static/svg/Health_learning.svg";
import Namaj_Learning from "../../assets/static/svg/Namaj_Learning.svg";
import Hajj_content from "../../assets/static/svg/Hajj_content.svg";
import Islamic_Story from "../../assets/static/svg/Islamic_Story.svg";
import {contentDetails, courseMaterial, digitalQuarn, featuredCourse, hadithLearning} from '../../utils/routes';
import DigitalContentBox from '../featuredCourse/digitalContentBox';
import FeaturedTop from '../featuredCourse/featuredTop';
import {hajjContent, islamicHistory, namajLearning} from "../../utils/Constants";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

class DigitalQuarnItem extends Component {
    render() {
        const {t} = this.props;
        return (

            <div className="digitalQuarnItem">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <FeaturedTop
                                title={t('LearnFromBangladeshBestIslamicScholars')}
                                para={t('WeBelieveThatEveryStudentLearnsDifferently')}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 SingleItem">
                            <DigitalContentBox
                                icon={Course_material}
                                title={t('courseMaterial')}
                                colorFill={'Course_material'}
                                discripTion={t('CanConnectOneToOne')}
                                reLink={courseMaterial}
                            />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 SingleItem">
                            <DigitalContentBox
                                icon={Digital_Quran}
                                title={t('DigitalQuran')}
                                colorFill={'Digital_Quran'}
                                discripTion={t('CanConnectOneToOne')}
                                reLink={digitalQuarn}
                            />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 SingleItem">
                            <DigitalContentBox
                                icon={Namaj_Learning}
                                title={t('namajLearning')}
                                colorFill={'Namaj_Learning'}
                                discripTion={t('CanConnectOneToOne')}
                                reLink={`${contentDetails}/${namajLearning}`}
                            />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 SingleItem">
                            <DigitalContentBox
                                icon={Health_learning}
                                title={t('HadithLearning')}
                                colorFill={'Health_learning'}
                                discripTion={t('CanConnectOneToOne')}
                                reLink={hadithLearning}
                            />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 SingleItem">
                            <DigitalContentBox
                                icon={Hajj_content}
                                title={t('HajjContent')}
                                colorFill={'Hajj_content'}
                                discripTion={t('CanConnectOneToOne')}
                                reLink={`${contentDetails}/${hajjContent}`}
                            />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 SingleItem">
                            <DigitalContentBox
                                icon={Islamic_Story}
                                title={t('islamicHistory')}
                                colorFill={'Islamic_Story'}
                                discripTion={t('CanConnectOneToOne')}
                                reLink={`${contentDetails}/${islamicHistory}`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({});
export default withTranslation()(connect(mapStateToProps)(DigitalQuarnItem));
