import React, { Component } from 'react';
import { getOtp, studentLogin } from '../../redux/actions/auth';
import { saveState } from '../../LocalStore/localStorage';
import { connect } from 'react-redux';
import decrypt from "../../utils/decrypt";


class JoinNow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: null,
            otp: null,
            inputOtp: null,
            loader: false,
            loaderOtp: false,
            validatePhoneNumber: false,
            invalidOtp: false
        }
    }

    submit = async (e) => {
        e.preventDefault();
        const { phoneNumber } = this.state;
        await this.setState({ loader: true });
        await this.props.dispatch(getOtp(phoneNumber));
        if (this.props.otp) {
            await this.setState({ otp: this.props.otp.otp, loader: false });
        }
    };

    submitOtp = async (e) => {
        e.preventDefault();
        const { inputOtp, otp, phoneNumber } = this.state;
        await this.setState({ loaderOtp: true });
        const decOpt = decrypt(otp);
        if (decOpt === inputOtp) {
            await this.props.dispatch(studentLogin(phoneNumber));
            await saveState('msisdn', phoneNumber);
            if (this.props.userInfo) {
                await saveState('uuid', this.props.userInfo.student_id);
                window.location.href = "/"
            }
            await this.setState({ loaderOtp: false });
        } else {
            await this.setState({ loaderOtp: false, invalidOtp: true });

        }
    };

    handleChange = (e) => {
        this.setState({ phoneNumber: e.target.value.trim() })
    };

    handleChangeOtp = (e) => {
        this.setState({ inputOtp: e.target.value.trim() })
    };

    handleClose = () => this.setState({ modalShow: false });


    render() {
        const { phoneNumber, loader, otp, invalidOtp } = this.state;

        return (
            <div className="joinBox" >

                {otp === null && !loader &&
                    <form onSubmit={this.submit} className="joinForm">
                        <div className="selectCountryCode">
                            <option value="0">+88</option>
                        </div>
                        <div className="joinInputBox">
                            <input
                                type="text"
                                step="1" min="0"
                                className="joinInput"
                                autoFocus={true}
                                autoComplete="off"
                                onChange={this.handleChange}
                                placeholder="Phone Number"
                                required

                            />
                            {((phoneNumber && phoneNumber.length < 11) || (phoneNumber && phoneNumber.length > 12)) &&
                                <small className="error text-danger">Invalid Phone Number</small>
                            }
                            <button className="joinBtn" type="submit">JOIN NOW</button>
                        </div>
                    </form>
                }
                {otp !== null && !loader &&
                    <form onSubmit={this.submitOtp} className="joinForm">
                        <div className="selectCountryCode">
                            <option value="0">OTP</option>
                        </div>
                        <div className="joinInputBox">
                            <input type="text" step="1" min="0" className="joinInput" placeholder="Enter OTP" onChange={this.handleChangeOtp} />
                            {invalidOtp &&
                                <p><small className="error text-danger">Invalid OTP</small></p>
                            }
                            <button className="joinBtn" type="submit">JOIN NOW</button>
                        </div>
                    </form>
                }
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    otp: state.otp,
    subInfo: state.subInfo,
    userInfo: state.userInfo,
    getMsisdn: state.getMsisdn

});
export default connect(mapStateToProps)(JoinNow);