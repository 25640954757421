import React from 'react'

function ClientSingleItem({ ClientImg, name, from, details }) {
    return (
        <div className="clientSingleItem">
            <div className="clientSayContent">
                <div className="clientSayInner">
                    <div className="clientSayDetails">
                        <p className="comma">“</p>
                        <p className="clientSay">{details}</p>
                    </div>
                </div>
            </div>
            <div className="clientDetailWrap">
                <div className="clientDetailsInner">
                    <div className="clientImg">
                        <img src={ClientImg} alt="ClientImg" />
                    </div>
                    <div className="clientDetails">
                        <h5 className="clientName">{name}</h5>
                        <p className="cleintFrom">{from}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientSingleItem
