import http from "../../apis/service";
import {getMsisdn, login, sentOtp, student, studentInfo} from "../../apis/endPoint";
import {
    FETCH_MSISDN,
    FETCH_OTP,
    FETCH_SUB_INFO,
    FETCH_USER_INFO,
    ON_DEMAND,
    ON_DEMAND_COURSE_MATERIAL_DOWNLOAD_API,
    UPDATE_PROFILE_IMAGE
} from "../index";

export const getOtp = (phoneNumber) => async dispatch => {
    const response = await http.get(`${sentOtp}/${phoneNumber}`);
    dispatch({
        type: FETCH_OTP,
        payload: response
    });
};

export const studentLogin = (student_msisdn) => async dispatch => {
    const response = await http.postWithBody(`${login}`, {
        student_msisdn,
        channel: 'web'
    });

    dispatch({
        type: FETCH_USER_INFO,
        payload: response.user_info
    });
    dispatch({
        type: FETCH_SUB_INFO,
        payload: response.sub_info
    });
};

export const getStudentInfo = (uuid) => async dispatch => {
    const response = await http.get(`${student}/${uuid}`);
    dispatch({
        type: FETCH_USER_INFO,
        payload: response.user_info
    });
    dispatch({
        type: FETCH_SUB_INFO,
        payload: response.sub_info
    });
};


export const onDemandSub = (msisdn) => async dispatch => {
    const response = await http.onDemandSub(`Subscription_API/soap/iqra_on_demand.php?channel=WEB&pack=on_demand&msisdn=88${msisdn}`);
    dispatch({
        type: ON_DEMAND,
        payload: response
    });
};

// Thank you for Iqra One to One enrollment. Your course will start from May 03 2020, class time 3:30pm, weekday-sunday,tuesday,Thursday.
export const courseMaterialDownloadLink = (msisdn) => async dispatch => {
    const response = await http.onDemandSub(`SMS_API/seendSmsgw.php?Username=iqra&Password=iqra@16560&shortcode=16560&msisdn=88${msisdn}&msg=Dear Subscriber, please download your course material from the link: `);
    dispatch({
        type: ON_DEMAND_COURSE_MATERIAL_DOWNLOAD_API,
        payload: response
    });
};

// UPDATE PROFILE IMAGE
export const updateProfileImage = (profile_pic, student_id) => async dispatch => {

    let formData = new FormData();
    formData.append('profile_pic', profile_pic);
    formData.append('student_id', student_id);

    const data = await http.putWithFormData(
        student,
        formData,
        {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
    ).catch(error => {
        console.log(error);
    });
    console.log(data);

    dispatch({
        type: UPDATE_PROFILE_IMAGE,
        payload: data
    });
};