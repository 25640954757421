import {FETCH_TUITION, FETCH_TUITION_BY_ID} from "../index";
import http from "../../apis/service";
import {tuition} from "../../apis/endPoint";

export const getTuitions = () => async dispatch => {
    const response = await http.get(tuition);
    dispatch({
        type: FETCH_TUITION,
        payload: response
    });

};

export const getTuitionsByType = (contentType, age) => async dispatch => {
    const response = await http.get(`${tuition}?tuition_type=${contentType}&age=${age}`);
    dispatch({
        type: FETCH_TUITION,
        payload: response
    });

};

export const getTuitionByID = (id) => async dispatch => {
    const response = await http.get(`${tuition}/${id}`);
    dispatch({
        type: FETCH_TUITION_BY_ID,
        payload: response
    });
};