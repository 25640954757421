import React from 'react'
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";

const DigitalContentBox = ({ icon, title, colorFill, discripTion, reLink }) => {
    return (
        <Link className={`feSingleItem ${colorFill}`} to={reLink}>
            <div className="itemIcon">
                <ReactSVG src={icon} />
            </div>
            <div className="cont">
                <h4 className="fetitle">{title}</h4>
                <p className="fepara">{discripTion}</p>
            </div>
        </Link>
    )
}

export default DigitalContentBox
