import React from 'react';
import {ReactSVG} from "react-svg";
import {Link} from 'react-router-dom';

const IslamicLifeSingletem = ({icon, to, item, ln, title, date, time}) => {
    return (
        <div className="col-lg-3 col-md-3 col-sm-6 col-12 singleItem">
            <Link className="namazLearningSingleItem" to={to}>
                <div className="namazItemTop">
                    <div className="inamazTopIcon">
                        <ReactSVG src={icon}/>
                    </div>
                    <div className="videoCount">
                        <p>{date || ''}</p>
                        <p>{time || ''}</p>
                    </div>
                </div>
                <div className="namazItemFooter">
                    <h4 className="namazFooterTitle text-capitalize">{title}</h4>
                </div>
            </Link>
        </div>
    );
}

export default IslamicLifeSingletem;
