import React, {Component} from 'react';
import FeaturedCourse from '../featuredCourse/featuredCourse';
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import StremingMody from "../groupLearning/StremingMody";
import {getTodayTarabi} from "../../redux/actions/tarabi";

class Tarabi extends Component {

    constructor(props) {
        super(props);
    }

    async componentDidMount() {

        await this.props.dispatch(getTodayTarabi())
    }

    render() {
        const {todayTarabi} = this.props;
        return (
            <>
                {todayTarabi && todayTarabi.stream_url ?
                <StremingMody src={todayTarabi.stream_url}/>
                :
                <div className="featuredCourseWrap" id="landingFfeaturedCourse">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="featContent">
                                <h5 className="secTopTitle">No Stream  URL Found</h5>
                                <h2 className="secTitle">Not Found</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                }
                <FeaturedCourse/>
                <DownloadApp/>
                <OurClientSayAboutUs/>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    todayTarabi: state.todayTarabi
});
export default withTranslation()(connect(mapStateToProps)(Tarabi));