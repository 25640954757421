import React, {Component} from 'react';
import innerBannerBgImg from "../../assets/static/img/banner/innerBannerBg.jpg";
import BannerInner from '../banner/bannerInner';
import DigitalQuarnItem from '../digitalQuran/digitalQuarnItem';
import FeaturedCourse from '../featuredCourse/featuredCourse';
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import PriceTable from "../priceTable/priceTable";
import {sendAccessInfo} from "../../redux/actions/accessLog";

export class DigitalContent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

   async componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const {t} = this.props;
        return (
            <div className="digitalQuranPageWrap">
                <BannerInner innerBannerBgImg={innerBannerBgImg} title={t('DigitalContent')}/>
                <DigitalQuarnItem/>
                <FeaturedCourse/>
                <DownloadApp/>
                <OurClientSayAboutUs/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});
export default withTranslation()(connect(mapStateToProps)(DigitalQuarnItem));
