import React from 'react';
import QuizItem from './quizItem';

const QuizSingleBody = () => {
    
    return (
        <div className="quizSingleBody">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-4 col-sm-6 col-12">
                        <div className="quizBody">
                            <h4 className="text-center stepTitle">First Steps</h4>
                            <div className="step">
                                <div className="stepsContent">
                                    <ul className="steps">
                                        <li className="active"></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                    <div className="stepesCount"> 2/5</div>
                                </div>
                            </div>
                            <p className="stepsPara text-center">Listen & Chose the correct answer </p>
                            <QuizItem />
                            <div className="btnNext">
                                <button className="btn form-control">Next</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuizSingleBody;
