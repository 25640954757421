import React, {Component} from 'react';
import VideoPlayer from "../layouts/player/videoPlayer";

class StremingMody extends Component {


    playListActive = (item, i) => {
        this.setState({item, activeId: i});
    };

    render() {
        return (
            <div className="CourseDetailsWrap">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="CourseDetailsInner">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="courseImgSliderContent">
                                            <VideoPlayer src={this.props.src} poster={'no'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StremingMody 
