import React from 'react'
import FeaturedTop from '../featuredCourse/featuredTop';
import MyCoursesTable from './myCoursesTable';


function MyCourseItem({myCourses}) {
    return (

        <div className="digitalQuarnItem">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <FeaturedTop title={"Your subscribed courses listed below"}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <MyCoursesTable myCourses={myCourses} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyCourseItem
