import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {liveStreaming, myCourses} from "../../utils/routes";

function EnrollConfirm({data, modalShow = true}) {
    const [show, setShow] = useState(true);
    const handleClose = () => {
        setShow(false)
        window.location.href = myCourses
    };
    // const handleShow = () => setShow(true);
    return (
        <>
            {data.status !== 400 &&
            <Modal className="enrollConfirm" show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title>Alhamdullilah</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>You have been successfully <b>Enrolled</b></h3>
                    <table className="table">
                        <tr>
                            <th>Module Name</th>
                            <td> :</td>
                            <td>{data.data.tuition.tuition_en || ' '}</td>
                        </tr>
                        <tr>
                            <th>Teacher Name</th>
                            <td> :</td>
                            <td>{data.data.instructor.first_name} {data.data.instructor.last_name}</td>
                        </tr>

                        <tr>
                            <th>Start Date</th>
                            <td> :</td>
                            <td>{data.data.start_date}</td>
                        </tr>
                        <tr>
                            <th>End Date</th>
                            <td> :</td>
                            <td>{data.data.end_date}</td>
                        </tr>
                        <tr>
                            <th>Start Time</th>
                            <td> :</td>
                            <td>{data.data.schedule.start_time}</td>
                        </tr>
                        <tr>
                            <th>End Time</th>
                            <td> :</td>
                            <td>{data.data.schedule.end_time}</td>
                        </tr>
                        <tr>
                            <th>Available day</th>
                            <td> :</td>
                            <td>
                                <ul className="availableWeekList">
                                    <li>Sa</li>
                                    ,
                                    <li>Mo</li>,
                                    <li>We</li>,
                                    <li>Fr</li>
                                </ul>
                            </td>
                        </tr>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            }
        </>
    );
}

export default EnrollConfirm;