export const home = "/home";
export const LandingHome = "#home";
export const contact = "/contact";
export const login = "/login";
export const price = "/price";
export const landingPrice = "#landingPrice";
export const Testimonial = "#Testimonial";
export const featured = "/featured";
export const landingFeatured = "#landingFeatured";
export const landingFfeaturedCourse = "#landingFfeaturedCourse";
export const featuredCourse = "/featured-course";
export const digitalClassRoom = "/digitalclassroom";
export const contentDetails = "/content-details";
export const courseDetails = "/course-details";
export const digitalQuarn = "/digital-quarn";
export const digitalContent = "/digital-content";
export const hadithLearning = "/hadith-learning";
export const myCourses = "/my-courses";
export const progressReport = "/progress-report";
export const groupLearning = "/group-learning";
export const liveStreaming = "/live-streaming";
export const userProfile = "/user-profile";
export const quiz = "/quiz";
export const notification = "/notification";
export const videoCalling = "/videoCalling";
export const courseMaterial = "/course-materials";
export const privacyPolicy = "/privacy-policy";
export const faqs = "/faqs";
export const islamicLifeStyle = "/islamic-life-style";
export const ramadanCalendar = "/ramadan-calendar";
export const tasbihClick = "/tasbih";
export const tarabi = "/tarabi";
export const ramadanForAirtel = "/ramadan-calendar-myairtel";