import React, {Component} from 'react';
import UserEnrollCoursesTable from './UserEnrollCoursesTable';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import ProfileImageUpdateReduxForm from "./ProfileImageUpdateReduxForm";
import {getStudentInfo, updateProfileImage} from "../../redux/actions/auth";
import {loadState} from "../../LocalStore/localStorage";
import {getMyCourse} from "../../redux/actions/myCourse";

export class UserInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            studentId: loadState('uuid'),

        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        const {studentId} = this.state;
      await this.props.dispatch(getMyCourse(studentId))

    }

    onSubmit = async (formValues) => {
        const {studentId} = this.state;
        await this.props.dispatch(updateProfileImage(formValues.profile_pic, studentId));
        await this.props.dispatch(getStudentInfo(studentId));


    };

    render() {
        const {userInfo, t, myCourses} = this.props;
        return (
            <div className="userInfoWrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-12 col-12">
                            <div className="userProfile">
                                <ProfileImageUpdateReduxForm userInfo={userInfo} onSubmit={this.onSubmit}/>
                                <div className="userInfoText">
                                    <h4 className="userName text-center">{userInfo.first_name + " " + userInfo.last_name} </h4>
                                    <p className="userType text-center">Student</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-12 col-12">
                            <div className="userDetailsWrap">
                                <div className="userenrCoursess">
                                    <div className="row">
                                        <div className="col">
                                            <div className="courseItem text-center">
                                                <h2>2</h2>
                                                <p>Enrollemnt Course</p>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="courseItem text-center">
                                                <h2>18</h2>
                                                <p>Classes completed</p>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="courseItem text-center">
                                                <h2>0</h2>
                                                <p>Course completed</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="userUpdateFormWrap">
                                <form action="#" className="userUpdateForm">
                                    <div className="basicInfo">
                                        <h4 className="basicInfoTitle">Edit Basic Info</h4>
                                        <div className="row">
                                            <div className="col-12 form-group">
                                                <p className="inputTitle">Name</p>
                                                <input type="text" className="form-control" placeholder={"Name"}/>
                                            </div>
                                            <div className="col-12 form-group">
                                                <p className="inputTitle">Email</p>
                                                <input type="email" className="form-control" placeholder={"Email"}/>
                                            </div>
                                            <div className="col-12 form-group">
                                                <p className="inputTitle">Msisdn <span
                                                    className="required text-danger">*</span></p>
                                                <input type="email" className="form-control"
                                                       placeholder={"Mobile Number"}/>
                                            </div>
                                            <div className="col-12 form-group">
                                                <p className="inputTitle">Date of Birth</p>
                                                <input type="date" className="form-control"
                                                       placeholder={"Date of Birth"}/>
                                            </div>
                                            <div className="col-12 form-group">
                                                <p className="inputTitle">Gender</p>
                                                <div className="genderSelect">
                                                    <div className="selectGender">
                                                        <div className="genInput">
                                                            <input type="radio" name="gender" id="male"/>
                                                            <label htmlFor="male"> Male</label>
                                                        </div>
                                                    </div>
                                                    <div className="selectGender">
                                                        <div className="genInput">
                                                            <input type="radio" name="gender" id="female"/>
                                                            <label htmlFor="female"> Female</label>
                                                        </div>
                                                    </div>
                                                    <div className="selectGender">
                                                        <div className="genInput">
                                                            <input type="radio" name="gender" id="other"/>
                                                            <label htmlFor="other"> Other</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="saveBtn text-right">
                                        <button className="btn btnSave">Save</button>
                                    </div>
                                </form>
                            </div>
                            <div className="enrolledCourse">
                                <h4 className="enrolledCourseTitle">Enrolled Course</h4>
                                <UserEnrollCoursesTable myCourses={myCourses}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    subInfo: state.subInfo,
    userInfo: state.userInfo,
    myCourses: state.myCourses
});
export default withTranslation()(connect(mapStateToProps)(UserInfo));
