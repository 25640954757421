import React, { Component } from 'react';
import FeaturedTop from './featuredTop';
import FeaturedCourseSingleItem from './featuredCourseSingleItem';
import { connect } from "react-redux";
import { getTuitions } from "../../redux/actions/tuition";
import { isAdult, isChildhood, isSeniorCitizen, isTeen } from "../../utils/Constants";
import { Tabs, Tab } from 'react-bootstrap';
import FeaturedCourseSlider from './featuredCourseSlider';
import {withTranslation} from "react-i18next";
import {FeaturedPage} from "./featuredCourses";

export class FeaturedCoursesInnerItem extends Component {

    constructor(props) {
        super();
        this.state = {
            tuitions: [],
        }
    }

    componentDidMount = async () => {
        const { tuitions } = this.state;
        if (tuitions && tuitions.length < 1) {
            await this.props.dispatch(getTuitions());
            await this.setState({ tuitions: this.props.tuitions })
        }
        window.scrollTo(0, 0);
    };

    render() {
        const { tuitions } = this.state;
        const {t} = this.props;
        return (
            <div className="FeaturedInnerItem">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <FeaturedTop
                                title={t('LearnFromBangladeshBestIslamicScholars')}
                                para={t('WeBelieveThatEveryStudentLearnsDifferently')}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="featContent">
                                <h4 className="secTitle">{t('featuredCourses')}</h4>
                            </div>
                        </div>
                        {tuitions && tuitions.length > 0 && tuitions.map((tuition, i) =>
                            <div className="col-md-3 col-sm-6 col-12" key={i}>
                                <FeaturedCourseSingleItem tuition={tuition} ln={t('ln')} duration={t('Duration')}/>
                            </div>
                        )}
                    </div>
                    {/* <div className="row">
                        <div className="col-12">
                            <FeaturedCourseSlider tuitions={tuitions} />
                        </div>
                        <div className="col-12">
                            <FeaturedCourseSlider tuitions={tuitions} />
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    tuitions: state.tuitions
});

export default withTranslation()(connect(mapStateToProps)(FeaturedCoursesInnerItem));

