import React, { Component } from 'react';
import BannerInner from '../banner/bannerInner';
import HadithLearningBgImg from "../../assets/static/img/banner/hadith_learning.png";
import UserInfo from './userInfo';

export class UserProfile extends Component {

   async componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <div className="userProfileWrap">
                <BannerInner innerBannerBgImg={HadithLearningBgImg} title={"Profile"} />
                <UserInfo />
            </div>
        );
    }
}

export default UserProfile;
