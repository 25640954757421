import React, {Component} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FeaturedCourseSingleItem from './featuredCourseSingleItem';

function FeaturedCourseSlider({tuitions, ln, duration}) {
    let settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              arrows: false,
            }
          }
        ]
    };
    return (
        <Slider className="row featuredCourseSlider" {...settings}>
            {tuitions && tuitions.length > 0 && tuitions.map((tuition, i) =>
                <div className="col-12 featuredCourseSliderItem" key={i}>
                    <FeaturedCourseSingleItem tuition={tuition} ln={ln} duration={duration}/>
                </div>
            )}
        </Slider>
    );
}

export default FeaturedCourseSlider;
