import React from 'react';
import oneToOneChatImg from "../../assets/static/img/VideoChat/oneToOne.jpg";
import callerImg from "../../assets/static/img/VideoChat/callerImg.jpg";
import { loadState } from "../../LocalStore/localStorage";
import Iframe from 'react-iframe'


const OneToOneChat = ({ instructorId }) => {
    return (
        <div className="CourseDetailsWrap">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="CourseDetailsInner">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-12">
                                    {/* <iframe
                                        src={`https://test.iqra-live.com/?room=${instructorId}-${loadState('uuid')}`}
                                        className="videoChat" allow="camera; microphone"></iframe> */}
                                        
                                    <Iframe
                                        url={`https://test.iqra-live.com/?room=${instructorId}-${loadState('uuid')}`}
                                        className="videoChat"
                                        allow="camera; microphone"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OneToOneChat;
