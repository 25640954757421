import React, { Component } from 'react';
import BannerInner from '../banner/bannerInner';
import innerBannerBgImg from "../../assets/static/img/banner/innerBannerBg.jpg";
import FeaturedCourse from './featuredCourse';
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import FeaturedInnerItem from './featuredInnerItem';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {sendAccessInfo} from "../../redux/actions/accessLog";

export class DigitalClassRoom extends Component {

  async  componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        const {t} = this.props;
        return (
            <div className="FeaturedPageWrap">
                <BannerInner innerBannerBgImg={innerBannerBgImg} title={t('feature')} />
                <FeaturedInnerItem t={t}/>
                <FeaturedCourse />
                <DownloadApp />
                <OurClientSayAboutUs />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

export default withTranslation()(connect(mapStateToProps)(DigitalClassRoom));