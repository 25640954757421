import React, { Component } from 'react';
import FeaturedTop from '../featuredCourse/featuredTop';
import NotefySingleItem from './notefySingleItem';

class NotificationBody extends Component {
    
    render() {
        return (
            <div className="digitalQuarnItem">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <FeaturedTop
                                title={"Get all notificatios from the below list"}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="notifyTable">
                                <p className="notifyCount">You have Total 11 Notifications</p>
                                <div className="tableContent">
                                    <NotefySingleItem />
                                    <NotefySingleItem />
                                    <NotefySingleItem />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotificationBody;
