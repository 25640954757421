import {FETCH_DIGITAL_QURAN_CONTENT} from "../index";
import http from "../../apis/service";
import {digitalQuran} from "../../apis/endPoint";

export const getDigitalQuranContents = (pageNumber, items) => async dispatch => {
    const response = await http.get(digitalQuran + `?page=${pageNumber}&pageSize=${items}`);
    dispatch({
        type: FETCH_DIGITAL_QURAN_CONTENT,
        payload: response
    });
};