import {combineReducers} from "redux";
import { reducer as reduxFormReducer } from 'redux-form';
import digitalContentsReducer from "./digitalContents/digitalContentsReducer";
import digitalQuranContentReducer from "./digitalQuran/digitalQuranContentReducer";
import sliderReduce from "./slider/sliderReduce";
import tuitionReducers from "./tuition/tuitionReducers";
import tuitionByIdReducer from "./tuition/tuitionByIdReducer";
import scheduleByTuitionAndGender from "./schedule/scheduleByTuitionAndGender";
import availableDateByScheduleReducer from "./schedule/availableDateByScheduleReducer";
import postEnrollReducer from "./enrollment/postEnrollReducer";
import deleteEnrollmentReducer from "./enrollment/deleteEnrollmentReducer";
import hadithReducer from "./hadith/hadithReducer";
import hadithChapterReducer from "./hadith/hadithChapterReducer";
import hadithChapterContentReducer from "./hadith/hadithChapterContentReducer";
import otpReducer from "./auth/otpReducer";
import subInfoReducer from "./auth/subInfoReducer";
import userInfoReducer from "./auth/userInfoReducer";
import myCourseReducer from "./myCourse/myCourseReducer";
import progressReportReducer from "./myCourse/progressReportReducer";
import getInstructorReducer from "./instructor/getInstructorReducer";
import courseMaterialReducer from "./courseMaterial/courseMaterialReducer";
import setLocalizationReducer from "./localization/setLocalizationReducer";
import getStudentReducer from "./student/getStudentReducer";
import getSubInfoReducer from "./subInfo/getSubInfoReducer";
import getMsisdn from "./auth/getMsisdn";
import OnDemandReducer from "./auth/onDemandReducer";
import todayTarabiReducer from "./tarabi/todayTarabiReducer";
import getSehriAndIftarTimeReducer from "./ramadan/getSehriAndIftarTimeReducer";

export default combineReducers({
    digitalContents: digitalContentsReducer,
    digitalQuranContents: digitalQuranContentReducer,
    sliders: sliderReduce,
    tuitions: tuitionReducers,
    tuition: tuitionByIdReducer,
    scheduleByTuitionAndGender: scheduleByTuitionAndGender,
    availableDateBySchedule: availableDateByScheduleReducer,
    postEnroll: postEnrollReducer,
    deleteEnroll: deleteEnrollmentReducer,
    hadith: hadithReducer,
    hadithChapters: hadithChapterReducer,
    hadithChapterContents: hadithChapterContentReducer,
    otp: otpReducer,
    subInfo: subInfoReducer,
    userInfo: userInfoReducer,
    myCourses: myCourseReducer,
    instructors: getInstructorReducer,
    courseMaterials: courseMaterialReducer,
    localizationReducer: setLocalizationReducer,
    form: reduxFormReducer, // mounted under "form"
    getStudent: getStudentReducer,
    getSubInfo: getSubInfoReducer,
    getMsisdn: getMsisdn,
    progressReport: progressReportReducer,
    OnDemandReducer: OnDemandReducer,
    todayTarabi: todayTarabiReducer,
    getSehriAndIftarTime: getSehriAndIftarTimeReducer,
});