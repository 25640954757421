import React from 'react';
import LangChanger from "../../redux/helper/lngChanger";

function HadithDetailsItem({ hadithChapters, hadithContentRows, hadithContent, t, getHadithChapterContent, playListActive, activeId, openHadithOnMobile, close }) {
    return (
        <div className="CourseDetailsWrap">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="CourseDetailsInner">
                            <div className="row">
                                <div className="col-12">
                                    <div className="hadithChapter">
                                        <div className="selectHadithChapter">
                                            <select className="form-control"
                                                onChange={(e) => getHadithChapterContent(e)}>
                                                {hadithChapters && hadithChapters.length > 0 && hadithChapters.map((item, i) =>
                                                    <option key={i}
                                                        value={item.id}>{LangChanger(t('ln'), item.chapter_title_en, item.chapter_title_bn)}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row column-reverse">
                                {hadithContent && Object.keys(hadithContent).length > 0 &&
                                    <div className={`col-lg-8 col-md-8 col-sm-12 col-12 courseDetailsLeft hadithForMobile ${close ? "active" : ""} `}>
                                        <div className="courseImgSliderContent">
                                            <div className="hadithBody">
                                                <div className="hadith">
                                                    <div className="hadithTop"><div className="goBack" onClick={() => openHadithOnMobile()}><i className="flaticon-left-arrow"></i></div>
                                                        <p><b>Ohir Shuchona</b> : ( Hadith no - 1 ) </p>
                                                    </div>
                                                    <h5>{LangChanger(t('ln'), hadithContent.hadith_content_title_en, hadithContent.hadith_content_title_bn)}</h5>
                                                    <br />
                                                    <div dangerouslySetInnerHTML={{
                                                        __html: LangChanger(t('ln'), hadithContent.description_en, hadithContent.description_bn)
                                                    }}></div>

                                                    <br />
                                                    <div dangerouslySetInnerHTML={{
                                                        __html: hadithContent.description_arabic
                                                    }}></div>
                                                    <div className="hadithBottm d-flex justify-content-between">
                                                        <div className="goBack"><i className="flaticon-left-arrow"></i></div>
                                                        <div className="goBack"><i className="flaticon-next"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-lg-4 col-md-4 col-sm-12 col-12 courseDetailsRight hadithForMobile">
                                    <div className="playListWrap">
                                        <ul className="plauList">
                                            {hadithContentRows && hadithContentRows.length > 0 && hadithContentRows.map((hadithContentRow, i) =>
                                                <li key={i}
                                                    className={activeId === i ? 'active' : ''}
                                                    onClick={() => playListActive(hadithContentRow, i)}>
                                                    <div className="ago">
                                                        <div className="agoText">
                                                            <span
                                                                className="wCount">{hadithContentRow.hadith_number}</span>
                                                        </div>
                                                    </div>
                                                    <div className="moduleName">
                                                        <p>{LangChanger(t('ln'), hadithContentRow.hadith_content_title_en, hadithContentRow.hadith_content_title_bn)}</p>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HadithDetailsItem
