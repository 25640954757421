import React, { Component } from 'react';
import BannerInner from '../banner/bannerInner';
import innerBannerBgImg from "../../assets/static/img/banner/hadith_learning.png";
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import NotificationBody from './notificationBody';

class Notification extends Component {
    
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="notificationWrap">
            <BannerInner innerBannerBgImg={innerBannerBgImg} title={"IQRA Notification"} />
            <NotificationBody />
            <DownloadApp />
            <OurClientSayAboutUs />
            </div>
        );
    }
}

export default Notification;
