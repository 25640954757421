import React, { Component } from 'react';
import BannerInner from '../banner/bannerInner';
import innerBannerBgImg from "../../assets/static/img/banner/innerBannerBg.jpg";
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import FeaturedCoursesInnerItem from './featuredCoursesInnerItem';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {sendAccessInfo} from "../../redux/actions/accessLog";

export class FeaturedPage extends Component {

   async componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {
        const {t} = this.props;
        return (
            <div className="FeaturedPageWrap">
                <BannerInner innerBannerBgImg={innerBannerBgImg} title={t('featuredCourses')} />
                <FeaturedCoursesInnerItem />
                <DownloadApp />
                <OurClientSayAboutUs />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    localization: state.localizationReducer,
});

export default withTranslation()(connect(mapStateToProps)(FeaturedPage));
