import React, { Component } from 'react'
import { Button, Form } from "react-bootstrap";
import { getOtp, studentLogin } from "../../redux/actions/auth";
import decrypt from '../../utils/decrypt';
import { connect } from "react-redux";
import { saveState } from "../../LocalStore/localStorage";

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: null,
            otp: null,
            inputOtp: null,
            loader: false,
            loaderOtp: false
        }
    }

    submit = async (e) => {
        e.preventDefault();
        const { phoneNumber } = this.state;
        await this.setState({ loader: true });
        await this.props.dispatch(getOtp(phoneNumber));
        if (this.props.otp) {
            await this.setState({ otp: this.props.otp.otp, loader: false });
        }
    };

    submitOtp = async (e) => {
        e.preventDefault();
        let { inputOtp, otp, phoneNumber } = this.state;
        await this.setState({ loaderOtp: true });
        const decOpt = decrypt(otp);
        if (decOpt === inputOtp) {
            await this.props.dispatch(studentLogin(phoneNumber));
            if (this.props.userInfo) {
                saveState(this.props.userInfo.student_id);
                window.location.href = "/"
            }
            await this.setState({ loaderOtp: false });
        }
    };

    handleChange = (e) => {
        this.setState({ phoneNumber: e.target.value.trim() })
    };

    handleChangeOtp = (e) => {
        this.setState({ inputOtp: e.target.value.trim() })
    };

    render() {
        const { phoneNumber, loader, otp, loaderOtp } = this.state;
        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-4"></div>
                        <div className="col-4">
                            {otp !== null && !loader &&
                                <Form className="m-5" onSubmit={this.submitOtp}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>OTP</Form.Label>
                                        <Form.Control type="text" onChange={this.handleChangeOtp}
                                            placeholder="Enter OTP" />
                                    </Form.Group>

                                    <Button variant="danger" type="submit" disabled={loaderOtp}>
                                        Submit {loaderOtp && <i className="spinner-border text-muted mt-2 mb-2"></i>}
                                    </Button>
                                </Form>
                            }
                            {otp === null && !loader &&

                                <Form className="m-5" onSubmit={this.submit}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <Form.Control type="text" onChange={this.handleChange} placeholder="Enter Mobile" />
                                        <Form.Text className="text-muted">
                                            We'll never share your phone number with anyone else.
                                    </Form.Text>
                                    </Form.Group>

                                    <Button variant="primary" type="submit" disabled={loader}>
                                        Submit {loader && <i className="spinner-border text-muted mt-2 mb-2"></i>}
                                    </Button>
                                </Form>
                            }
                        </div>
                        <div className="col-4"></div>
                    </div>
                </div>

            </>
        );
    }
}

const mapStateToProps = (state) => ({
    otp: state.otp,
    subInfo: state.subInfo,
    userInfo: state.userInfo,
});
export default connect(mapStateToProps)(Login);