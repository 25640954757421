import React, { Component } from 'react';
import JoinNow from '../common/joinNow';


import { connect } from "react-redux";
import { getStudentInfo } from "../../redux/actions/auth";
import { withTranslation } from 'react-i18next';
import { loadState } from '../../LocalStore/localStorage';

export class BannerInner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            studentId: loadState('uuid'),
        }
    }
    render() {
        const { innerBannerBgImg, title } = this.props;
        const { studentId } = this.state;

        return (
            <div className="bannerInnerWrap" id="home">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="innerBanner" style={{ backgroundImage: `url(${innerBannerBgImg})` }}>
                                <div className="overlay"></div>
                                <div className="innerBannerTop">
                                    <h4 className="text-center secTitle2">{title}</h4>
                                </div>
                                <div className="bannerContent">
                                    <div className="innerBannerBottom">
                                        {!studentId &&
                                            <JoinNow />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    subInfo: state.subInfo,
});

export default withTranslation()(connect(mapStateToProps)(BannerInner));
