import React from 'react';
import {Link} from "react-router-dom";

const CategoryBox = ({icon, title, colorFill, reLink}) => {
    return (
        <div className="col-md-6 col-sm-6 col-12">
            <Link to={reLink} className={`catSingleItem ${colorFill}`}>
                <div className="itemInner">
                    <div className="catSingIcon">
                        <img src={icon} alt="icon"/>
                    </div>
                    <div className="catSingContent">
                        <h4 className="text-center">{title}</h4>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default CategoryBox;
