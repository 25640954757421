import React, {Component} from 'react';
import VideoPlayer from "../layouts/player/videoPlayer";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import LangChanger from "../../redux/helper/lngChanger";

class CourseMainBody extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: null,
            activeId: 0,
        };

        this.playListActive = this.playListActive.bind(this);
    }

    playListActive = (item, i) => {
        this.setState({item, activeId: i});
    };

    render() {
        const {item, activeId} = this.state;
        const {contents, ln} = this.props;
        return (
            <div className="CourseDetailsWrap">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="CourseDetailsInner">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-12 courseDetailsLeft">
                                        {item === null &&

                                        <div className="courseImgSliderContent">
                                            {/*<CourseImgSlider/>*/}
                                            {
                                                contents.length > 0 && contents.slice(0, 1)[0] &&
                                                <>
                                                    <VideoPlayer src={contents.slice(0, 1)[0].content_url}
                                                                 poster={contents.slice(0, 1)[0].postal_img_small}/>
                                                </>
                                            }
                                        </div>
                                        }
                                        {item !== null &&

                                        <div className="courseImgSliderContent">
                                            <VideoPlayer src={item.content_url} poster={item.postal_img_small}/>
                                        </div>
                                        }
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 courseDetailsRight">
                                        <div className="playListWrap">
                                            <ul className="plauList">
                                                {contents.length > 0 && contents.map((content, i) =>
                                                    <li className={activeId === i ? 'active' : ''}
                                                        key={i} onClick={() => this.playListActive(content, i)}>
                                                        <div className="ago">
                                                            <div className="agoText">
                                                                <span className="wCount">{i + 1}</span>
                                                            </div>
                                                        </div>
                                                        <div className="moduleName">
                                                            <OverlayTrigger
                                                                placement='right'
                                                                overlay={
                                                                    <Tooltip
                                                                        className="tooltip">
                                                                        <small><small>{LangChanger(ln, content.content_title_en, content.content_title_bn)}&nbsp;/&nbsp;{content.content_title_arabic}</small></small>
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <p>{LangChanger(ln, content.content_title_en, content.content_title_bn)}&nbsp;/&nbsp;{content.content_title_arabic}</p>
                                                            </OverlayTrigger>{' '}
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CourseMainBody
