import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ClientSingleItem from './clientSingleItem';
import { clientImg1, clientImg2, clientImg3, clientImg4 } from '../../utils/Constants';

export class OurClientSayAboutUs extends Component {
    render() {
        const settings = {
            autoplay: true,
            dots: false,
            infinite: true,
            speed: 1500,
            autoplayspeed: 3500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]

        };
        return (
            <div className="ourClientSayWrap" id="Testimonial">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="ourClientTopContent">
                                <h2 className="secTitle2 text-center"> Scholars say about us <span className="icon-surah1" data-reactid="44"></span> </h2>
                            </div>
                        </div>
                    </div>
                    <Slider className="row ourClientSlider" {...settings}>
                        <div className="col-12 ourClientSliderItem">
                            <ClientSingleItem ClientImg={clientImg1} name={`Hafez Mawlana Ishak Mamun`} from={`Director, Nurani Talimul Quran Board`} details={`Technology works wonder and IQRA has set an example of Distant Learning Approach in Bangladesh`} />
                        </div>
                        <div className="col-12 ourClientSliderItem">
                            <ClientSingleItem ClientImg={clientImg2} name={`Mawlana Mostafizur Rahman `} from={`Assistant Director, Islamic Foundation Bangladesh`} details={`IQRA will help Muslims around the country to learn the Holy Quran through qualified teachers`} />
                        </div>
                        <div className="col-12 ourClientSliderItem">
                            <ClientSingleItem ClientImg={clientImg3} name={`Mawlana Nur Uddin`} from={`Assistant Director, Islamic Foundation Bangladesh`} details={`We want to spread the light of Holy Quran and IQRA helps to reach the last mile`} />
                        </div>
                        <div className="col-12 ourClientSliderItem">
                            <ClientSingleItem ClientImg={clientImg4} name={`Mawlana Md. Zakir Hossain`} from={`Assistant Director, Islamic Foundation Bangladesh`} details={`Students now can learn the Holy Quran through IQRA from anywhere and anytime`} />
                        </div>
                    </Slider>
                </div>
            </div>
        );
    }
}

export default OurClientSayAboutUs;
