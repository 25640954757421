import React from 'react'
import {ReactSVG} from "react-svg";
import One_to_one from "../../assets/static/svg/one_to_one.svg";
import Group_learning from "../../assets/static/svg/Group_learning.svg";
import Quiz_session from "../../assets/static/svg/Quiz_session.svg";
import Exam_session from "../../assets/static/svg/Exam_session.svg";
import {Link} from 'react-router-dom';
import DigitalContentBox from './digitalContentBox';
import {featuredCourse, groupLearning, quiz} from '../../utils/routes';
import FeaturedTop from './featuredTop';
import {isChildhood, oneToMany, oneToOne} from "../../utils/Constants";

function FeaturedInnerItem({t}) {
    return (
        <div className="FeaturedInnerItem">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <FeaturedTop
                            title={t('LearnFromBangladeshBestIslamicScholars')}
                            para={t('WeBelieveThatEveryStudentLearnsDifferently')}
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 SingleItem">
                        <DigitalContentBox
                            icon={One_to_one}
                            title={t('OneToOne')}
                            colorFill={'oneToOne'}
                            discripTion={t('CanConnectOneToOne')}
                            reLink={`${featuredCourse}/${oneToOne}/${isChildhood}`}
                        />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 SingleItem">
                        <DigitalContentBox
                            icon={Group_learning}
                            title={t('GroupLearning')}
                            colorFill={'group'}
                            discripTion={t('CanConnectOneToOne')}
                            reLink={`${groupLearning}`}
                        />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 SingleItem">
                        <DigitalContentBox
                            icon={Quiz_session}
                            title={t('QuizSessions')}
                            colorFill={'quiz'}
                            discripTion={t('CanConnectOneToOne')}
                            reLink={quiz}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeaturedInnerItem
