import React from 'react'
import MyCourseLogo from "../../assets/static/img/course/myCoursesLogo.png";
import courseProfileFemale from "../../assets/static/svg/courseProfileFemale.svg";
import courseProfileMale from "../../assets/static/svg/courseProfileMale.svg";
import {ReactSVG} from 'react-svg';
import ProgressSingleItem from "./progressSingleItem";
import {imageBaseUrl} from "../../redux/apis/api";
import LangChanger from "../../redux/helper/lngChanger";
import {avater} from "../../utils/Constants";
import moment from 'moment';


function MyCourseProgressItem({progressReport, t}) {
    return (

        <div className="digitalQuarnItem">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 col-sm-12 col-12">
                        <div className="courseDetailsSection">
                            <div className="profileImg">
                                <ReactSVG
                                    src={progressReport.gender === 'female' ? courseProfileFemale : courseProfileMale}/>
                            </div>
                            <h4 className="courseName">{t('Course')}: <b>{LangChanger(t('ln'), progressReport.tuition_en, progressReport.tuition_bn)}</b>
                            </h4>
                            <p className="courseInstructor">Course
                                Instructor: <b>{progressReport.first_name} {progressReport.last_name}</b></p>
                            <div className="userProfilePic">
                                <img
                                    src={progressReport.instructor_pic ? imageBaseUrl + progressReport.instructor_pic : avater}
                                    alt="userProfilePic" className="img-fluid rounded-circle"/>
                            </div>
                            <div className="enrollInfo">
                                <table className="table enrollInfoTable">
                                    {/*<tr>*/}
                                    {/*    <td>Enroll date:</td>*/}
                                    {/*    <td className="text-right"><b>{moment(progressReport.enroll_date).format('d-m-y')}</b></td>*/}
                                    {/*</tr>*/}
                                    <tr>
                                        <td>Start date:</td>
                                        <td className="text-right"><b>{progressReport.start_date}</b></td>
                                    </tr>
                                    <tr>
                                        <td>End date:</td>
                                        <td className="text-right"><b>{progressReport.end_date}</b></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="availableWeek">
                                <p>Available day in week:</p>
                                <ul className="availableWeekList">
                                    <li>Sa</li>
                                    <li className="active">Su</li>
                                    <li>Mo</li>
                                    <li className="active">Tu</li>
                                    <li>We</li>
                                    <li className="active">Th</li>
                                    <li>Fr</li>
                                </ul>
                            </div>
                            <div className="enrollInfo">
                                <table className="table enrollInfoTable">
                                    <tr>
                                        <td>Class start time:</td>
                                        <td className="text-right"><b>10:00 am</b></td>
                                    </tr>
                                    <tr>
                                        <td>Class start time:</td>
                                        <td className="text-right"><b>10:00 am</b></td>
                                    </tr>
                                    <tr>
                                        <td>Class start time:</td>
                                        <td className="text-right"><b>10:00 am</b></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="enrollProgress">
                                <ProgressSingleItem progressTitle={"Overall Progres"} now={"78"}/>
                                <ProgressSingleItem colorFill={'completeClasses'} progressTitle={"Complete Classes"}
                                                    completeClasses={"3"} now={"60"}/>
                                <ProgressSingleItem colorFill={'remainingClasses'} progressTitle={"Remaining Classes"}
                                                    remainingClasses={"1"} now={"50"}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 col-sm-12 col-12">
                        <div className="myCourseLogo text-right">
                            <div className="poweredByLogo">
                                <img src={MyCourseLogo} alt="myCourseLogo"/>
                                <p className="text-center">Offerd By</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyCourseProgressItem
