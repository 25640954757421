import React from 'react'
import { Accordion, Card } from 'react-bootstrap';

    
const FaqSingleItem = ({ id, title, close,discripTion ,toggleClass}) => {
    return (
        
        <div className="faqSungleItem">
        <Accordion.Toggle as={Card.Header} eventKey={id}>
            <h5 className="faqQuestion" onClick={() => toggleClass()}> <span className="qNumber">Q {id}.</span> {title}
        <span className="rightIcon float-right">
                    <i className={`fa fa-angle-${close ? 'up' : 'down'}`}></i>
                </span>
            </h5>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={id}>
            <Card.Body>
                <div className="ansBody">
                    <p>You can learn Noorani based Quaidah in 6 modules based on Arabic letters, Haraf, Makhraj, Harkat, Tanbeen, Sakeen, formation of words and sentences, and Arabic grammar in a scientific manner by an Islamic Scholar verified by Islamic Foundation Bangladesh.</p>
                </div>
            </Card.Body>
        </Accordion.Collapse>
    </div>
    )
}

export default FaqSingleItem
