import React, {Component} from 'react';
import PriceTable from './priceTable';
import FeaturedCourse from '../featuredCourse/featuredCourse';
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import BannerInner from '../banner/bannerInner';
import innerBannerBgImg from "../../assets/static/img/banner/banner_bg_img_1.jpg";
import {sendAccessInfo} from "../../redux/actions/accessLog";
import {connect} from "react-redux";

export class PricePage extends Component {

    async componentDidMount() {
        window.scrollTo(0, 0);
        await this.props.dispatch(sendAccessInfo());

    }

    render() {
        return (
            <>
                <div className="pricePageWrap">
                    <BannerInner innerBannerBgImg={innerBannerBgImg} title={"Pricing"}/>
                    <PriceTable/>
                    <FeaturedCourse/>
                    <DownloadApp/>
                    <OurClientSayAboutUs/>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(PricePage);
