import {ACCESS_LOG} from "../index";
import http from "../../apis/service";
import {accessLog} from "../../apis/endPoint";

export const sendAccessInfo = (msisdn) => async dispatch => {
    const response = await http.postWithBody(accessLog, {
        "msisdn": `88${msisdn}`,
        "channel": "web",
        "request_url": window.location.href,
    });
    dispatch({
        type: ACCESS_LOG,
        payload: response
    });

};