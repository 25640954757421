import React from 'react';

function Loader({height}) {
    return(
        <div className="d-flex align-items-center justify-content-center" style={{height: height}}>
            <i className="spinner-border text-muted mt-2 mb-2"></i>
        </div>
    )
}

export default Loader;

