import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactSVG } from "react-svg";
import GooglePlay from "../../assets/static/svg/Google_playstore_01.svg";
import AppStore from "../../assets/static/svg/Apple_App_store.svg";
import ShapeBg from "../../assets/static/img/download_app/app_download_round_shape.png";
import LandingPage from "../../assets/static/img/download_app/Landing_page.jpg";
import LandingPage1 from "../../assets/static/img/download_app/Landing_page1.jpg";
import MobileShape from "../../assets/static/img/download_app/Mobile_Png.png";
import {withTranslation} from "react-i18next";
import {Category} from "../category/category";
import {connect} from "react-redux";

export class DownloadApp extends Component {
    render() {

        const {t} = this.props;
        const settings = {
            autoplay: true,
            dots: false,
            infinite: true,
            speed: 3000,
            autoplayspeed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true
        };
        return (
            <div className="downlodAppWrap" id="downlodAppWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="appLeftContent">
                                <h2 className="secTitle">{t('DownloadTheIQRAAPP')}</h2>
                                <p className="appPara">{t('IncreaseYourIslamic')}</p>
                                <div className="appBtnGroup">
                                    <a href="https://play.google.com/store/apps/details?id=com.purpledigit.iqra" className="appBtn">
                                        <ReactSVG src={GooglePlay} />
                                    </a>
                                    <a href="https://apps.apple.com/us/app/iqra-digital-quran-learning/id1523199047" className="appBtn">
                                        <ReactSVG src={AppStore} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mbHide">
                            <div className="appRightContent">
                                <div className="appBgShape">
                                    <img src={ShapeBg} alt="shape img" className="img-fluid" />
                                </div>
                                <div className="mobileShape">
                                    <img src={MobileShape} alt="MobileShape" className="img-fluid MobileShape" />
                                    <div className="appSliderWrap">
                                        <div className="sliderContent">
                                            <Slider className="appSlider" {...settings}>
                                                <div className="sliderItem">
                                                    <img src={LandingPage} alt="img" className="img-fluid" />
                                                </div>
                                                <div className="sliderItem">
                                                    <img src={LandingPage1} alt="img" className="img-fluid" />
                                                </div>
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    localization: state.localizationReducer,
});

export default withTranslation()(connect(mapStateToProps)(DownloadApp));
