import React, {Component} from 'react';
import {imageBaseUrl} from "../../redux/apis/api";
import CourseThumbnail from "../../assets/static/img/course/course_thumbnail.png";
import WhyChoseIcon from "../../assets/static/svg/whyChoseIcon.svg";
import WhyChoseItem from './whyChoseItem';
import FaqSingleItem from './faqSingleItem';
import {Accordion, Alert} from 'react-bootstrap';
import {connect} from "react-redux";
import {getTuitionByID} from "../../redux/actions/tuition";
import Loader from "../common/Loader";
import {getAvailableDate, getScheduleByTuitionAndGender} from "../../redux/actions/schedule";
import DataNotFoundError from "../common/dataNotFoundOrError";
import {onDemandRegularPrice} from "../../utils/Constants";
import {postEnroll, postEnrollDelete} from "../../redux/actions/enrollment";
import EnrollConfirm from "../modal/EnrollConfirm";
import {loadState} from "../../LocalStore/localStorage";
import {withTranslation} from "react-i18next";
import LangChanger from "../../redux/helper/lngChanger";
import {ON_DEMAND} from "../../redux/actions";
import {courseMaterialDownloadLink, onDemandSub} from "../../redux/actions/auth";
import {myCourses} from "../../utils/routes";
import {Link} from "react-router-dom";
import {sendAccessInfo} from "../../redux/actions/accessLog";

export class CourseDetailsPages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            close: true,
            id: null,
            tuitionConfigId: null,
            tuition: [],
            schedules: [],
            availableDateBySchedule: [],
            enrollBtnLoader: false,
            postEnrol: {},
            uuid: loadState('uuid'),
            msisdn: loadState('msisdn'),
            warningMsg: ''
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.params) {
            this.setState({id: this.props.match.params.id})
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.id !== this.state.id) {
            await this.props.dispatch(getTuitionByID(this.state.id));
            await this.setState({tuition: this.props.tuition});
            if (this.props.tuition) {
                window.scrollTo(0, 0);
                await this.props.dispatch(getScheduleByTuitionAndGender(this.state.tuition.id, 'male')); // for default value first time
                this.setState({schedules: this.props.scheduleByTuitionAndGender})
            }
        }
    }

    toggleClass = () => {
        this.setState({
            close: !this.state.close,
        });
    };

    enroll = async () => {
        await this.setState({enrollBtnLoader: true});
        const {tuitionConfigId, uuid, msisdn} = this.state;
        if (tuitionConfigId !== null && uuid) {
            await this.props.dispatch(postEnroll(tuitionConfigId, uuid));
            if (this.props.postEnroll.data && this.props.postEnroll.data.id) {
                await this.props.dispatch(onDemandSub(msisdn));
                if (this.props.OnDemand && this.props.OnDemand.trim() === 'failed') {
                    await this.props.dispatch(postEnrollDelete(this.props.postEnroll.enroll.id));
                    await window.scrollTo(0, 0);

                } else {
                    await this.props.dispatch(courseMaterialDownloadLink(msisdn)); // Course Material Download Link send sms.
                    await this.setState({postEnrol: this.props.postEnroll});
                }
            } else {
                await this.setState({warningMsg: this.props.postEnroll.data.message});
                await window.scrollTo(0, 0);
            }
        } else {
            await this.setState({enrollBtnLoader: false});
            alert('please select available date');
        }
        await this.setState({enrollBtnLoader: false});
        // postEnroll
    };

    getSlotData = async (tuition, gender) => {
        await this.props.dispatch(getScheduleByTuitionAndGender(tuition, gender));
    };

    getAvailableDate = async (e) => {
        const {id} = this.state;
        await this.props.dispatch(getAvailableDate(id, e.target.value));
        await this.setState({availableDateBySchedule: this.props.availableDateBySchedule})

    };

    render() {
        const {close, tuition, schedules, availableDateBySchedule, enrollBtnLoader, postEnrol, warningMsg} = this.state;
        const {t, deleteEnroll} = this.props;
        if (tuition.message) {
            return <DataNotFoundError height="600px" message={tuition.message}/>
        }
        return (
            <div className="CourseDetailsWrap courseDteailsBannerWrap">
                <div className="container">
                    {/* {postEnrol.status === 200 && */}
                    <>
                        {Object.keys(postEnrol).length > 0 && <EnrollConfirm data={postEnrol} modalShow={true}/>}
                    </>
                    {/* } */}
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                            <div className="courseDteailsBannerContent">
                                <div className="courseDetailsBannerTop">
                                    {!tuition && <Loader height={'50px'}/>}

                                    {schedules.length < 1 &&
                                    <Alert variant="danger" dismissible>
                                        <Alert.Heading><i className="fa fa-warning"></i> {t('OhUserYouGotWarning')}
                                        </Alert.Heading>
                                        <p>{t('ThereAreNoAvailableSlotsForThisCourse')}</p>
                                    </Alert>
                                    }

                                    {/* Message From Enrollment */}
                                    {deleteEnroll.message &&
                                    <Alert variant="warning" dismissible>
                                        <Alert.Heading><i className="fa fa-warning"></i> Message</Alert.Heading>
                                        <p>Payment Failed ! Please Recharge and try again .</p>
                                    </Alert>
                                    }
                                    {warningMsg !== '' &&
                                    <Alert variant="warning" dismissible>
                                        <Alert.Heading><i className="fa fa-warning"></i> Message</Alert.Heading>
                                        <p>{warningMsg} <Link to={myCourses}> Go to MyCourse</Link></p>
                                    </Alert>
                                    }
                                    {tuition &&
                                    <h2 className="courseName secTitle2">{LangChanger(t('ln'), tuition.tuition_en, tuition.tuition_bn)}</h2>
                                    }
                                </div>
                                <div className="aboutCourse">
                                    <div className="row align-items-end">
                                        {!tuition && <Loader height={'50px'}/>}

                                        {tuition &&
                                        <div className="col-md-9 col-sm-9 col-12">
                                            <h4 className="aboutTitle">About the course</h4>
                                            <div dangerouslySetInnerHTML={{
                                                __html: LangChanger(t('ln'), tuition.description_en, tuition.description_bn)
                                            }}></div>
                                        </div>
                                        }
                                        <div className="col-md-3 col-sm-3 col-12">
                                            <div className="offeredLogo">
                                                <div className="logoImg text-center">
                                                    {tuition &&
                                                    <img
                                                        src={`${imageBaseUrl}${tuition.icon_url}`}
                                                        alt={imageBaseUrl}/>
                                                    }
                                                </div>
                                                <p className="text-center">{t('OfferedBy')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="whyChoseCourse">
                                    <h3 className="whyTitle">{t('WhyChoseThisCourse')}</h3>
                                    <div className="row">
                                        <WhyChoseItem icon={WhyChoseIcon} title={t('Recognition')}
                                                      discripTion={t('RecognizingTheArabicAlphabets')}/>
                                        <WhyChoseItem icon={WhyChoseIcon} title={t('Recognition')}
                                                      discripTion={t('RecognizingTheArabicAlphabets')}/>
                                        <WhyChoseItem
                                            icon={WhyChoseIcon} title={t('Recognition')}
                                            discripTion={t('RecognizingTheArabicAlphabets')}/>
                                        <WhyChoseItem icon={WhyChoseIcon} title={t('Recognition')}
                                                      discripTion={t('RecognizingTheArabicAlphabets')}/>
                                        <WhyChoseItem
                                            icon={WhyChoseIcon} title={t('Recognition')}
                                            discripTion={t('RecognizingTheArabicAlphabets')}/>
                                        <WhyChoseItem
                                            icon={WhyChoseIcon} title={t('Recognition')}
                                            discripTion={t('RecognizingTheArabicAlphabets')}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="coursePriceRightContent">
                                <div className="courseThumbnail">
                                    <img src={CourseThumbnail} alt="CourseThumbnail" className="img-fluid"/>
                                </div>
                                <div className="coursePriceContent">
                                    <div className="coursePrice">
                                        {tuition.tuition_type !== 'one_to_many' &&
                                        <h2 className="price">
                                            <span className="opperPrice">BDT 99 <small>+(VAT, SC & SC)</small> </span>

                                            {/*<span className="previusPrice">*/}
                                            {/*    <del> ${onDemandRegularPrice}</del>  87% off*/}
                                            {/*    </span>*/}
                                        </h2>
                                        }
                                        <div className="courseIncludes">
                                            <h5>{t('ThisCourseIncludes')}</h5>
                                            <ul className="courseIncludesList">
                                                {tuition &&
                                                <li>{tuition.duration * tuition.total_class} M {t('onDemandVideo')}</li>}
                                                <li>{t('FullLifetimeAccess')}</li>
                                                <li>{t('AccessOnMobileAndPC')}</li>
                                                <li>{t('CertificateOfCompletion')}</li>
                                            </ul>
                                        </div>
                                        {tuition &&
                                        <div className="availableSlot">
                                            <h5>{t('Gender')} <span className="text-danger">*</span></h5>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio"
                                                       onClick={() => this.getSlotData('male')} name="gender"
                                                       id="male" value="male" checked
                                                       disabled={tuition.isMale ? "" : "disabled"}/>
                                                <label className="form-check-label" htmlFor="male">
                                                    {t('Male')}
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="gender"
                                                       id="female" value="female"
                                                       disabled={tuition.isFemale ? "" : "disabled"}/>
                                                <label className="form-check-label" htmlFor="female">
                                                    {t('Female')}
                                                </label>
                                            </div>
                                        </div>
                                        }
                                        <div className="availableSlot">
                                            <h5>{t('AvailableSlot')} <span className="text-danger">*</span></h5>
                                            <select className="availableSlotSelect form-control"
                                                    onChange={(e) => this.getAvailableDate(e)}>
                                                <option value="0">Select Slot</option>
                                                {schedules && schedules.map((schedule, i) =>
                                                    <option value={schedule.id}
                                                            key={i}>{schedule.start_time} -- {schedule.end_time}</option>
                                                )}
                                            </select>
                                        </div>

                                        <div className="availableSlot">
                                            <h5>{t('AvailableDate')} <span className="text-danger">*</span></h5>
                                            <select className="availableSlotSelect form-control"
                                                    onChange={(e) => this.setState({tuitionConfigId: e.target.value})}>
                                                <option value="">Select Date</option>
                                                {availableDateBySchedule && availableDateBySchedule.map((date, i) =>
                                                    <option value={date.id}
                                                            key={i}>{date.start_date} -- {date.end_date}</option>
                                                )}
                                            </select>
                                        </div>


                                        <div className="courseSkills">
                                            <h5>{t('SkillsYouWillGain')}</h5>
                                            {!tuition && <Loader height={"100px"}/>}
                                            {tuition && tuition.tags &&
                                            <ul className="skillsList">
                                                {tuition.tags.split(',').map((tag, i) =>
                                                    <li key={i}>{tag}</li>
                                                )}
                                            </ul>
                                            }
                                        </div>
                                        {tuition.tuition_type !== 'one_to_many' &&
                                        <div className="enroll">
                                            {/*If Schedule not found*/}
                                            {schedules.length < 1 &&
                                            <button className="enrollBtn"
                                                    disabled={true}>{t('EnrollNow')} {enrollBtnLoader &&
                                            <i className="spinner-border text-muted mt-2 mb-2"></i>}
                                            </button>

                                            }

                                            {/*If Schedule Found*/}
                                            {schedules.length > 0 &&
                                            <button className="enrollBtn" disabled={enrollBtnLoader}
                                                    onClick={() => this.enroll()}>{t('EnrollNow')} {enrollBtnLoader &&
                                            <i className="spinner-border text-muted mt-2 mb-2"></i>}
                                            </button>

                                            }
                                        </div>
                                        }
                                        {tuition.tuition_type === 'one_to_many' &&
                                        <div className="enroll">
                                            <button className="enrollBtn"> Live</button>
                                        </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="courseFaqWrap">
                                <div className="faqTop">
                                    <h3 className="text-center faqTitle">Frequently Asked Questions</h3>
                                    <p className="text-center faqPara">We're Here to Help. We value our
                                        relationship
                                        with you and strive to provide you with assistance and answers when you
                                        need
                                        it.</p>
                                </div>
                                <div className="courseFaq">

                                    <Accordion defaultActiveKey="">
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'1'}
                                                       title={`What is IQRA? `}/>
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'2'}
                                                       title={`How can a student learn? `}/>
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'3'}
                                                       title={`What is the process to learn? `}/>
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'4'}
                                                       title={`When can an individual learn from IQRA Platform? `}/>
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'5'}
                                                       title={`How this course will help me to learn qaidah?`}/>
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'6'}
                                                       title={`How i complete my payment?`}/>
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'7'}
                                                       title={`Can i just enroll into one course?`}/>
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'8'}
                                                       title={`How often is this course is special?`}/>
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'9'}
                                                       title={`Can i read arabi letter after this course?`}/>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    tuition: state.tuition,
    scheduleByTuitionAndGender: state.scheduleByTuitionAndGender,
    availableDateBySchedule: state.availableDateBySchedule,
    postEnroll: state.postEnroll,
    getSubInfo: state.getSubInfo,
    OnDemand: state.OnDemandReducer,
    deleteEnroll: state.deleteEnroll,

});

export default withTranslation()(connect(mapStateToProps)(CourseDetailsPages));
