import React, {Component} from 'react';
import BannerInner from '../banner/bannerInner';
import HadithLearningBgImg from "../../assets/static/img/banner/hadith_learning.png";
import FeaturedCourse from '../featuredCourse/featuredCourse';
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import MyCourseProgressItem from "./myCourseProgressItem";
import {connect} from "react-redux";
import {fullProgressReport} from "../../redux/actions/myCourse";
import {withTranslation} from "react-i18next";
import {sendAccessInfo} from "../../redux/actions/accessLog";

export class MyCourseProgressReport extends Component {

    async componentDidMount() {
        await this.props.dispatch(sendAccessInfo());

        const {studentId, tuition_config_id} = this.props.match.params;
        if (studentId !== null && tuition_config_id !== null) {
            await this.props.dispatch(fullProgressReport(tuition_config_id, studentId));
        }
        window.scrollTo(0, 0);
    }

    render() {
        const {progressReport, t} = this.props;
        return (
            <div className="myCoursesWrap">
                <BannerInner innerBannerBgImg={HadithLearningBgImg} title={"Progress Report"}/>
                <MyCourseProgressItem progressReport={progressReport} t={t}/>
                {/*<OtherCourses/>*/}
                <FeaturedCourse/>
                <DownloadApp/>
                <OurClientSayAboutUs/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    progressReport: state.progressReport
});

export default withTranslation()(connect(mapStateToProps)(MyCourseProgressReport));
