import React from 'react'


const ContactSingleItem = ({ icon, title, contactInfo }) => {
    return (
        <div className="col-lg-3 col-md-3 col-sm-6 col-12">
            <div className="contactRequestItem">
                <div className="itemInner">
                    <div className="icon text-center">
                        <i className={icon}></i>
                    </div>
                    <div className="content">
                        <h4 className="text-center conTitle">{title}</h4>
                        <p className="text-center conPara">{contactInfo}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactSingleItem
