import React from 'react';
import { ProgressBar } from 'react-bootstrap';

const ProgressSingleItem = ({ progressTitle, now, completeClasses, remainingClasses, colorFill }) => {
    return (
        <div className={`courseProgress ${colorFill}`}>
            <p className="progressCount">
                <span className="progressTitle">{progressTitle}</span>
                {now && (progressTitle === "Overall Progres" ) && <small className="float-right">{`${now}% Complete`}</small>}
                {completeClasses && (progressTitle === "Complete Classes" ) && <small className="float-right">{`${completeClasses} ${completeClasses > 1 ? 'Classes' : 'Classe'}`}</small>}
                {remainingClasses && (progressTitle === "Remaining Classes" ) && <small className="float-right">{`${remainingClasses} ${remainingClasses > 1 ? 'Classes' : 'Classe'}`}</small>}
            </p>
            <ProgressBar now={now} />
        </div>
    );
}

export default ProgressSingleItem;
