import React, {Component} from 'react';
import FeaturedCourse from '../featuredCourse/featuredCourse';
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import HadithDetailsItem from './hadithDetailsItem';
import {getHadithChapter, getHadithChapterContent} from "../../redux/actions/hadith";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {sendAccessInfo} from "../../redux/actions/accessLog";

export class HadithDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            hadithChapters: [],
            hadithContentRows: [],
            hadithContent: {},
            page: 1,
            pageSize: 20,
            itemCount: 0,
            hadithContentCount: 0,
            activeId: 0,
            close: false,
        };
        this.getHadithChapterContent = this.getHadithChapterContent.bind(this);
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        await this.props.dispatch(sendAccessInfo());

        if (this.props.match.params) {
            this.setState({id: this.props.match.params.id})
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        const {page, pageSize} = this.state;
        if (prevState.id !== this.state.id) {
            await this.props.dispatch(getHadithChapter(this.state.id, page, pageSize));
            const {count, rows} = this.props.hadithChapters;
            await this.props.dispatch(getHadithChapterContent(rows[0].id, 1, 20));
            const {count: hadithContentCount, rows: hadithContentRows} = this.props.hadithChapterContents;
            this.setState({
                hadithChapters: rows,
                itemCount: count,
                hadithContentCount,
                hadithContentRows,
                hadithContent: hadithContentRows[0]
            });
        }
    }

    getHadithChapterContent = async (e) => {
        await this.props.dispatch(getHadithChapterContent(e.target.value, 1, 20));
        const {count: hadithContentCount, rows: hadithContentRows} = this.props.hadithChapterContents;
        this.setState({
            hadithContentCount,
            hadithContentRows,
            hadithContent: hadithContentRows[0],
            activeId: 0
        });
    };

    playListActive = (item, i) => {
        this.setState({hadithContent: item, activeId: i, close: !this.state.close});
    };
    

    openHadithOnMobile = () => {
        this.setState({
            close: !this.state.close,
        });
    };

    render() {
        const {hadithChapters, hadithContentRows, hadithContent, activeId ,close} = this.state;
        const {t} = this.props;
        return (
            <div className="hadithDetailsWrap">
                <HadithDetailsItem hadithChapters={hadithChapters} hadithContentRows={hadithContentRows}
                                   getHadithChapterContent={this.getHadithChapterContent} hadithContent={hadithContent}
                                   t={t} playListActive={this.playListActive} openHadithOnMobile={this.openHadithOnMobile} close={close} activeId={activeId}/>
                <FeaturedCourse/>
                <DownloadApp/>
                <OurClientSayAboutUs/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    hadithChapters: state.hadithChapters,
    hadithChapterContents: state.hadithChapterContents
});
export default withTranslation()(connect(mapStateToProps)(HadithDetails));
