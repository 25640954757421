import React, { Component } from 'react';
import innerBannerBgImg from "../../assets/static/img/banner/innerBannerBg.jpg";
import BannerInner from '../banner/bannerInner';
import ContactRequest from './contactRequest';
import ContactMap from './contactMap';
import {sendAccessInfo} from "../../redux/actions/accessLog";
import {connect} from "react-redux";

export class ContactPage extends Component {

   async componentDidMount() {
        window.scrollTo(0, 0);

    }

    render() {
        return (
            <div className="contactPageWrap">
                <BannerInner innerBannerBgImg={innerBannerBgImg} title={"Contact"} />
                <ContactRequest />
                <ContactMap />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ContactPage);
