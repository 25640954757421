import React, { Component } from 'react';
import RamadanCalendarItem from './RamadanCalendarItemFoeAirtel';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import {getSehriAndIftarTime} from "../../redux/actions/ramadan";


class RamadanForAirtel extends Component {
    constructor(props) {
        super(props);
    }

   async componentDidMount() {
       await this.props.dispatch(getSehriAndIftarTime())
    }
    render() {
        const { getSehriAndIftarTime, t } = this.props;

        return (
            <div className="ramadanForAirtel">
                <div className="border"></div>
                <RamadanCalendarItem getSehriAndIftarTime={getSehriAndIftarTime} t={t} />
                <div className="border"></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    getSehriAndIftarTime: state.getSehriAndIftarTime
});
export default withTranslation()(connect(mapStateToProps)(RamadanForAirtel));
