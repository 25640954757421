import React, {Component, Suspense} from 'react';
import BannerInner from '../banner/bannerInner';
import innerBannerBgImg from "../../assets/static/img/banner/innerBannerBg.jpg";
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import FeaturedCourse from '../featuredCourse/featuredCourse';
import {connect} from "react-redux";
import {getInstructor} from "../../redux/actions/instructor";
import Loader from "../common/Loader";
import {sendAccessInfo} from "../../redux/actions/accessLog";
import {loadState} from "../../LocalStore/localStorage";

const LiveInstructorItem = React.lazy(() => import('./LiveInstructorItem'));


export class LiveInstructorList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            instructors: [],
        }
    }

    async componentDidMount() {
        await this.props.dispatch(getInstructor());
        await this.setState({instructors: this.props.instructors})
    }

    render() {
        const {instructors} = this.state;
        return (
            <div className="FeaturedPageWrap LiveInstructorList">
                <BannerInner innerBannerBgImg={innerBannerBgImg} title={"Group Learning"}/>
                <Suspense fallback={<Loader height={"556px"}/>}>
                    <LiveInstructorItem instructors={instructors}/>
                </Suspense>

                <FeaturedCourse/>
                <DownloadApp/>
                <OurClientSayAboutUs/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    instructors: state.instructors
});
export default connect(mapStateToProps)(LiveInstructorList);
