import React, { Component } from 'react';
import { BannerInner } from '../banner/bannerInner';
import HadithLearningBgImg from "../../assets/static/img/banner/hadith_learning.png";
import RamadanCalendarItem from './RamadanCalendarItem';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getSehriAndIftarTime} from "../../redux/actions/ramadan";

class RamadanCalendar extends Component {

    constructor(props) {
        super(props);
    }

   async componentDidMount() {
       await this.props.dispatch(getSehriAndIftarTime())
    }

    render() {
        const {getSehriAndIftarTime, t} = this.props;
        return (
            <div className="ramadanCalendarWrap">
                <BannerInner innerBannerBgImg={HadithLearningBgImg} title={'Ramadan Calendar'} />
                <RamadanCalendarItem getSehriAndIftarTime={getSehriAndIftarTime} t={t}/>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    getSehriAndIftarTime: state.getSehriAndIftarTime
});
export default withTranslation()(connect(mapStateToProps)(RamadanCalendar));
