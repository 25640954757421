import React from 'react'
import courseThamImg from "../../assets/static/img/featured-course/featured_course_img_1.png";
import {imageBaseUrl} from "../../redux/apis/api";
import {Link} from 'react-router-dom';
import {courseDetails} from "../../utils/routes";
import ProgressSingleItem from '../myCourseProgressReport/progressSingleItem';
import LangChanger from "../../redux/helper/lngChanger";

function FeaturedCourseSingleItem({tuition, ln, duration}) {
    return (
        <Link className="featuredSingleItem" to={`${courseDetails}/${tuition.id}`}>
            <div className="courseThamImg">
                <img src={courseThamImg} alt="img"/>
            </div>
            <div className="featuredLogo">
                <img src={imageBaseUrl + tuition.icon_url} alt="logo"/>
            </div>
            <div className="featuredContent">
                {/* <p className="teachersName text-center">Mufti Abu Bakkar</p> */}
                <h4 className="courseName text-center">{LangChanger(ln, tuition.tuition_en, tuition.tuition_bn)}</h4>
            </div>
            <div className="FeaturedCourseProgress">
                <ProgressSingleItem now={tuition.course_progress || 1}/>
            </div>
            <div className="featuredCourseFooter">
                <ul className="courTimeList">
                    <li>
                        <span>{duration}</span>
                        <span>{tuition.duration}</span>
                    </li>
                </ul>
            </div>
        </Link>
    )
}

export default FeaturedCourseSingleItem
