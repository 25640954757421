import React from 'react';
import { progressReport } from '../../utils/routes';

const FeaturedTop = ({ title, para, subTitle }) => {
    return (
        <div>
            <div className="featuredTop">
                {title && <h4 className="text-center secTitle2">{title}</h4>}
                {subTitle && <p className="text-center subTitle">{subTitle}</p>}
                {para && <p className="text-center fePara">{para}</p>}
            </div>
        </div>
    );
}

export default FeaturedTop;
