import React, {Component} from 'react';
import BannerInner from '../banner/bannerInner';
import HadithLearningBgImg from "../../assets/static/img/banner/hadith_learning.png";
import MyCourseItem from './myCourseItem';
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import FeaturedCourse from '../featuredCourse/featuredCourse';
import {connect} from "react-redux";
import {getMyCourse} from "../../redux/actions/myCourse";
import {loadState} from "../../LocalStore/localStorage";
import {sendAccessInfo} from "../../redux/actions/accessLog";

export class MyCourses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: loadState('uuid'),
        }
    }

   async componentDidMount() {
        await this.props.dispatch(sendAccessInfo());

        const {uuid} = this.state;
        if (uuid && uuid.length > 0) {
            this.props.dispatch(getMyCourse(uuid))
        }
        window.scrollTo(0, 0);
    }

    render() {
        const {myCourses} = this.props;
        return (
            <div className="myCoursesWrap">
                <BannerInner innerBannerBgImg={HadithLearningBgImg} title={"My Courses"} />
                <MyCourseItem myCourses={myCourses}/>
                <FeaturedCourse/>
                <DownloadApp/>
                <OurClientSayAboutUs/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    myCourses: state.myCourses
});

export default connect(mapStateToProps)(MyCourses);
