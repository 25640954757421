//All End point here

//Digital Content
export const digitalContent = '/digital-content';
//Digital Content
export const digitalQuran = '/digital-quran';
//Slider
export const slider = '/slider';
//Access Log
export const accessLog = '/access-log';
//tuition
export const tuition = '/tuition';

// Schedule
export const schedule = '/schedule';
export const scheduleDate = '/schedule-date';

//Enrollment
export const enrollment = '/enrollment';

//hadith
export const hadith = '/hadith';
export const hadithChapter = `${hadith}/chapter`;
export const hadithChapterContent = `${hadith}/content`;

//Auth and Student
export const student = '/student';
export const sentOtp = '/sent-otp';
export const login = `${student}/login`;
export const subInfo = '/info';
export const getMsisdn = '/get-msisdn';

//MyCourse
export const myCourse = '/enrollment/me';
export const progress = '/progress';

//Instructor
export const instructor = '/instructor';
export const instructorOneToMany = '/type/one_to_many';


//Course Materials
export const courseMaterialDetail = '/course-materials';


//tarabi
export const tarabi = '/tarabi';

//Ifter and sheri
export const ramadan = '/ramadan';



