import React from 'react';
import { myCoursesLogo } from '../../utils/Constants';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NotefySingleItem = () => {
    return (
        <div className="notefySingleItem">
            <div className="tableDropDown">
                <div className="ago">1 min ago</div>
                <div className="dots">
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <i className="fa fa-ellipsis-h"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#"><i className="fa fa-trash"></i>Delete</Dropdown.Item>
                            <Dropdown.Item href="#"><i className="fa fa-eye-slash"></i>Unfollow</Dropdown.Item>
                            <Dropdown.Item href="#"><i className="fa fa-bell-slash"></i>Turn off</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <Link to={"/"} className="tableInfo">
                <div className="myCourseLogo">
                    <img src={myCoursesLogo} alt="myCourseLogo" className="img-fluid" />
                </div>
                <div className="notifyText">
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officiis sequi reiciendis natus repellat veniam sit labore ducimus aperiam consequuntur nam exercitationem ab corrupti autem, maxime perspiciatis eum! Sit quae dolores, ducimus delectus impedit dolor laborum, autem sint qui nihil corporis aspernatur saepe odit, quia explicabo quas temporibus expedita error ab.</p>
                </div>
            </Link>
        </div>
    );
}

export default NotefySingleItem;
