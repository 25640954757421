import React, {Component} from 'react';
import FeaturedCourse from '../featuredCourse/featuredCourse';
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import {connect} from "react-redux";
import {getCourseMaterials} from "../../redux/actions/courseMaterial";
import CourseMaterialMainBody from "./courseMaterialMainBody";
import {withTranslation} from "react-i18next";
import PriceTable from "../priceTable/priceTable";

class CourseMaterialDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            itemNumber: 10,
            contents: [],
            user_info: null,
        }
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        const {pageNumber, itemNumber} = this.state;
        await this.props.dispatch(getCourseMaterials(pageNumber, itemNumber));
        await this.setState({contents: this.props.courseMaterials});
        const {user_info} = this.props.getSubInfo.error ? null : this.props.getSubInfo;
        await this.setState({user_info});
    };
    render() {
        const {contents, user_info} = this.state;
        const {t} = this.props;
        return (
            <>
                {user_info && user_info.sub_status !== 0 &&
                <CourseMaterialMainBody contents={contents} t={t}/>
                }

                {user_info && user_info.sub_status === 0 &&
                <PriceTable/>
                }
                <FeaturedCourse/>
                <DownloadApp/>
                <OurClientSayAboutUs/>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    courseMaterials: state.courseMaterials,
    getSubInfo: state.getSubInfo,
});

export default withTranslation()(connect(mapStateToProps)(CourseMaterialDetail));