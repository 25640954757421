import React, { Component } from 'react';
import FeaturedCourseSlider from './featuredCourseSlider';
import { connect } from "react-redux";
import { getTuitions } from "../../redux/actions/tuition";
import {withTranslation} from "react-i18next";
import FeaturedCourseSingleItem from "./featuredCourseSingleItem";

export class FeaturedCourse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tuitions: [],
        }
    }

    componentDidMount = async () => {
        const { tuitions } = this.state;
        if (tuitions && tuitions.length < 1) {
            await this.props.dispatch(getTuitions());
            await this.setState({ tuitions: this.props.tuitions })
        }
        window.scrollTo(0, 0);
    };

    render() {
        const { tuitions } = this.state;
        const {t} = this.props;

        return (
            <div className="featuredCourseWrap" id="landingFfeaturedCourse">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="featContent">
                                <h5 className="secTopTitle">{t('StudentsSreViewing')}</h5>
                                <h2 className="secTitle">{t('featuredCourses')}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FeaturedCourseSlider tuitions={tuitions} ln={t('ln')} duration={t('Duration')}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    tuitions: state.tuitions
});

export default withTranslation()(connect(mapStateToProps)(FeaturedCourse));

