import React, {Component} from 'react';
import FeaturedCourse from '../featuredCourse/featuredCourse';
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import {getDigitalContents} from "../../redux/actions/digitalContents";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import CourseMainBody from './courseMainBody';
import PriceTable from "../priceTable/priceTable";
import CourseMaterialMainBody from "../courseMaterial/courseMaterialMainBody";
import {sendAccessInfo} from "../../redux/actions/accessLog";

export class ContentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            itemNumber: 10,
            contents: [],
            user_info: null
        };
    }

    componentDidMount = async () => {
        const {pageNumber, itemNumber} = this.state;
        await this.props.dispatch(sendAccessInfo());

        if (this.props.match.params.category && this.props.match.params.category !== "") {
            await this.props.dispatch(getDigitalContents(this.props.match.params.category, pageNumber, itemNumber));
            const {user_info} = this.props.getSubInfo.error ? null : this.props.getSubInfo;
            await this.setState({user_info});
            await this.setState({contents: this.props.digitalContents})
        }
        window.scrollTo(0, 0);
    };


    render() {
        const {contents, user_info} = this.state;
        const {t} = this.props;
        return (
            <>
                {user_info && user_info.sub_status !== 0 &&
                <CourseMainBody contents={contents} ln={t('ln')}/>
                }

                {user_info && user_info.sub_status === 0 &&
                <PriceTable/>
                }
                <FeaturedCourse/>
                <DownloadApp/>
                <OurClientSayAboutUs/>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    digitalContents: state.digitalContents,
    getSubInfo: state.getSubInfo,

});

export default withTranslation()(connect(mapStateToProps)(ContentDetails));