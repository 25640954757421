import {FETCH_INSTRUCTOR} from "../index";
import http from "../../apis/service";
import {instructorOneToMany, tuition} from "../../apis/endPoint";

export const getInstructor = () => async dispatch => {
    const response = await http.get(`${tuition}/${instructorOneToMany}`);
    dispatch({
        type: FETCH_INSTRUCTOR,
        payload: response
    });
};