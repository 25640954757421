import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import GooglePlay from "../../../assets/static/svg/Google_playstore_01.svg";
import FooterLogo from "../../../assets/static/img/logo.png";
import {
  contact,
  digitalClassRoom,
  digitalContent,
  faqs,
  privacyPolicy,
  groupLearning,
} from "../../../utils/routes";
import contentDetails from "../../contentDetails/contentDetails";
import { hajjContent, islamicLifeStyle } from "../../../utils/Constants";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AppStore from "../../../assets/static/svg/Apple_App_store.svg";

export class Footer extends Component {
  render() {
    const { t } = this.props;
    return (
      <footer className="footerWrap">
        <div className="footerTop">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="footerSingleItem">
                  <div className="footerLogoWrap">
                    <div className="footerLogo">
                      <a href="#">
                        <img src={FooterLogo} alt="footer logo" />
                      </a>
                    </div>
                    <div className="footerdetails">
                      <p className="footerPara">{t("HereAtIQRA")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="footerSingleItem">
                  <h5 className="footerTitle">Features</h5>
                  <ul className="footerMenu">
                    <li>
                      <Link to={digitalClassRoom}>{t("DigitalClassRoom")}</Link>
                    </li>
                    <li>
                      <Link to={digitalContent}>{t("DigitalContent")}</Link>
                    </li>
                    <li>
                      <Link to={`${contentDetails}/${hajjContent}`}>
                        {t("HajjContent")}
                      </Link>
                    </li>
                    <li>
                      <Link to={`${contentDetails}/${islamicLifeStyle}`}>
                        {t("IslamicLifeStyle")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="footerSingleItem">
                  <h5 className="footerTitle">Stay with Iqra</h5>
                  <ul className="footerMenu">
                    <li>
                      <Link to={"#"}>{t("aboutUs")}</Link>
                    </li>
                    <li>
                      <Link to={groupLearning}>{t("ExpertScholars")}</Link>
                    </li>
                    <li>
                      <Link to={contact}>{t("contact")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="footerSingleItem">
                  <h5 className="footerTitle">{t("Help")}</h5>
                  <ul className="footerMenu">
                    <li>
                      <Link to={faqs}>FAQs</Link>
                    </li>
                    <li>
                      <Link to={contact}>
                        {t("T_247")} {t("Help")}
                      </Link>
                    </li>
                    <li>
                      <Link to={privacyPolicy}>{t("PrivacyPolicy")}</Link>
                    </li>
                    {/* <li><Link to={'#'}>{t('TermsOfService')}</Link></li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="footerSingleItem">
                  <div className="footerdownloadApp">
                    <h5 className="footerTitle">{t("APP Available on")}</h5>
                    <p className="footerPara">{t("DownloadTheIQRAAPP")}</p>
                    <div className="appBtnGroup">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.purpledigit.iqra"
                        className="appBtn"
                      >
                        <ReactSVG src={GooglePlay} />
                      </a>
                      <br/>
                      <a
                        href="https://apps.apple.com/us/app/iqra-digital-quran-learning/id1523199047"
                        className="appBtn"
                      >
                        <ReactSVG src={AppStore} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerBottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-6 col-12">
                <div className="footerCopyRight">
                  <p className="copy">
                    &copy; {t("y2020")}{" "}
                    <a href="#">{t("PurpleDigitCommunicationLtd")}</a>{" "}
                    {t("AllRightsReserved")}
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-12">
                <div className="footerSocial">
                  <div className="solialLink">
                    <span>{t("findOnUs")}</span>
                    <span>
                      <a href="#">
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fa fa-youtube-play"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => ({
  localization: state.localizationReducer,
});

export default withTranslation()(connect(mapStateToProps)(Footer));
