export const FETCH_DIGITAL_CONTENT = 'FETCH_DIGITAL_CONTENT';
export const FETCH_DIGITAL_QURAN_CONTENT = 'FETCH_DIGITAL_QURAN_CONTENT';
export const FETCH_SLIDER = 'FETCH_SLIDER';
// TUITION CONSTANT
export const FETCH_TUITION = 'FETCH_TUITION';
export const FETCH_TUITION_BY_ID = 'FETCH_TUITION_BY_ID';

//SCHEDULE
export const FETCH_SCHEDULE_BY_TUITION_AND_GENDER = 'FETCH_SCHEDULE_BY_TUITION_AND_GENDER';
export const FETCH_SCHEDULE_DATE = 'FETCH_SCHEDULE_DATE';

//ENROLLMENT
export const POST_ENROLLMENT = 'POST_ENROLLMENT';
export const DELETE_ENROLLMENT = 'DELETE_ENROLLMENT';

//HADITH
export const FETCH_HADITH = 'FETCH_HADITH';
export const FETCH_HADITH_CHAPTER = 'FETCH_HADITH_CHAPTER';
export const FETCH_HADITH_CHAPTER_CONTENT = 'FETCH_HADITH_CHAPTER_CONTENT';

//Auth
export const FETCH_OTP = 'FETCH_OTP';
export const FETCH_USER_INFO = 'FETCH_USER_INFO';
export const GET_SUB_INFO = 'GET_SUB_INFO';
export const FETCH_SUB_INFO = 'FETCH_SUB_INFO';
export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
export const FETCH_MSISDN = 'FETCH_MSISDN';
export const ON_DEMAND = 'ON_DEMAND';
export const ON_DEMAND_COURSE_MATERIAL_DOWNLOAD_API = 'ON_DEMAND_COURSE_MATERIAL_DOWNLOAD_API';

//My Course
export const FETCH_MY_COURSE = 'FETCH_MY_COURSE';
export const FETCH_PROGRESS_REPORT = 'FETCH_PROGRESS_REPORT';

//Instructor
export const FETCH_INSTRUCTOR = 'FETCH_INSTRUCTOR';

//Instructor
export const FETCH_COURSE_MATERIAL = 'FETCH_COURSE_MATERIAL';

//Student
export const FETCH_STUDENT = 'FETCH_STUDENT';


export const LOCALIZATION = 'LOCALIZATION';

//Tarabi
export const TODAY_TARABI = 'TODAY_TARABI';

export const FETCH_IFTAR_AND_SHERI = 'FETCH_IFTAR_AND_SHERI';

export const ACCESS_LOG = 'ACCESS_LOG';


