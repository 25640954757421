import React, {Component} from 'react';
import {BannerInner} from '../banner/bannerInner';
import HadithLearningBgImg from "../../assets/static/img/banner/hadith_learning.png";
import FeaturedTop from "../featuredCourse/featuredTop";
import IslamicLifeSingletem from "./islamicLifeSingletem";
import Fajr_namaj from "../../assets/static/svg/Fajr_namaj.svg";
import {ramadanCalendar, tarabi, tasbihClick} from "../../utils/routes";
import tasbih from "../../assets/static/svg/tasbih.svg";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getTodayTarabi} from "../../redux/actions/tarabi";
import Namaj_Learning from "../../assets/static/svg/Namaj_Learning.svg";
import LangChanger from "../../redux/helper/lngChanger";

class IslamicLifeStyle extends Component {

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await this.props.dispatch(getTodayTarabi())
    }

    render() {
        const {t, todayTarabi} = this.props;
        return (
            <div className="NamazLearningPageWrap">
                <BannerInner innerBannerBgImg={HadithLearningBgImg} title={t('IslamicLifeStyle')}/>

                <div className="digitalQuarnItem">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <FeaturedTop
                                    title={t('LearnFromBangladeshBestIslamicScholars')}
                                    para={t('WeBelieveThatEveryStudentLearnsDifferently')}
                                />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <IslamicLifeSingletem icon={Fajr_namaj} to={ramadanCalendar}
                                                  title={t('RamadanSehriAndIftarTime2020')}/>
                            <IslamicLifeSingletem icon={tasbih} to={tasbihClick} title={t(`DigitalTasbih`)}/>
                            {/*<IslamicLifeSingletem icon={Namaj_Learning} to={Namaj_Learning}*/}
                            {/*                      title={t('OhirAlo')}/>*/}
                            {todayTarabi &&
                            <IslamicLifeSingletem icon={Namaj_Learning} to={todayTarabi.is_live ? tarabi : '#'}
                                                  date={todayTarabi.date} time={todayTarabi.time}
                                                  title={LangChanger(t('ln'), todayTarabi.title_en, todayTarabi.title_bn)}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    todayTarabi: state.todayTarabi
});
export default withTranslation()(connect(mapStateToProps)(IslamicLifeStyle));
