import React from 'react';

const QuizItem = () => {
    
    return (
        <div className="quizItemWrap">

            <div className="question text-center">
                <span>
                    <i className="fa fa-microphone"></i>
                </span>
            </div>

            <div className="quiz">
                <div className="quizItem">
                    <div className="selectquiz">
                        <div className="quizInput">
                            <input type="radio" name="quiz" id="quiz_1" />
                            <label htmlFor="quiz_1">ا</label>
                        </div>
                    </div>
                    <div className="selectquiz">
                        <div className="quizInput">
                            <input type="radio" name="quiz" id="quiz_2" />
                            <label htmlFor="quiz_2">ب</label>
                        </div>
                    </div>
                    <div className="selectquiz">
                        <div className="quizInput">
                            <input type="radio" name="quiz" id="quiz_3" />
                            <label htmlFor="quiz_3">ت</label>
                        </div>
                    </div>
                    <div className="selectquiz">
                        <div className="quizInput">
                            <input type="radio" name="quiz" id="quiz_4" />
                            <label htmlFor="quiz_4">ث</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuizItem;
