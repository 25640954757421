import React, {Component} from 'react';
import BannerInner from '../banner/bannerInner';
import innerBannerBgImg from "../../assets/static/img/banner/innerBannerBg.jpg";
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import FeaturedCourseSlider from './featuredCourseSlider';
import Tabs from 'react-bootstrap/Tabs'
import {Tab} from 'react-bootstrap';
import {getTuitionsByType} from "../../redux/actions/tuition";
import {connect} from "react-redux";
import {isAdult, isChildhood, isSeniorCitizen, isTeen} from "../../utils/Constants";
import {withTranslation} from "react-i18next";
import {sendAccessInfo} from "../../redux/actions/accessLog";

export class featuredCoursePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tuitions: [],
            contentType: '',
            age: '',
        }
        this.getTuitionByType = this.getTuitionByType.bind(this);
    }

    componentDidMount = async () => {
        await this.props.dispatch(sendAccessInfo());

        if (this.props.match) {
            const {contentType, age} = this.props.match.params;
            await this.props.dispatch(getTuitionsByType(contentType, age));
            await this.setState({tuitions: this.props.tuitions, contentType, age})
        }
        window.scrollTo(0, 0);
    };

    getTuitionByType = async (type) => {
        const {contentType} = this.props.match.params;
        await this.props.dispatch(getTuitionsByType(contentType, type));
        await this.setState({tuitions: this.props.tuitions})
    };

    render() {
        const {tuitions} = this.state;
        const {t} = this.props;
        return (
            <div className="FeaturedPageWrap">
                <BannerInner innerBannerBgImg={innerBannerBgImg} title={t("featuredCourses")}/>
                <div className="largestCoursesWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="largestCourseTop">
                                    <h5 className="text-center largecoTitle">{t('largestCoursesSelections')}</h5>
                                    <p className="text-center largecoPara">{t('ChooseFromOver100000OnlineVideo')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Tabs className="pricTAb" defaultActiveKey="is_childhood" onSelect={(k) => this.getTuitionByType(k)}>
                                    <Tab eventKey="is_childhood" title={t('Childhood')}>
                                        <FeaturedCourseSlider tuitions={tuitions} ln={t('ln')} duration={t('Duration')}/>
                                    </Tab>
                                    <Tab eventKey="is_teen" title={t('teen')}>
                                        <FeaturedCourseSlider tuitions={tuitions} ln={t('ln')} duration={t('Duration')}/>
                                    </Tab>
                                    <Tab eventKey="is_adult" title={t('Adult')}>
                                        <FeaturedCourseSlider tuitions={tuitions} ln={t('ln')} duration={t('Duration')}/>
                                    </Tab>
                                    <Tab eventKey="is_senior_citizen" title={t('SeniorCitizen')}>
                                        <FeaturedCourseSlider tuitions={tuitions} ln={t('ln')} duration={t('Duration')}/>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <DownloadApp/>
                <OurClientSayAboutUs/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    tuitions: state.tuitions
});

export default withTranslation()(connect(mapStateToProps)(featuredCoursePage));