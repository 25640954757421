import React, {Component} from 'react';
import {imageBaseUrl} from "../../../redux/apis/api";
import {Player, PlayerEvent} from 'bitmovin-player/modules/bitmovinplayer-core';
import EngineBitmovinModule from 'bitmovin-player/modules/bitmovinplayer-engine-bitmovin';
import MseRendererModule from 'bitmovin-player/modules/bitmovinplayer-mserenderer';
import HlsModule from 'bitmovin-player/modules/bitmovinplayer-hls';
import AbrModule from 'bitmovin-player/modules/bitmovinplayer-abr';
import ContainerTSModule from 'bitmovin-player/modules/bitmovinplayer-container-ts';
import SubtitlesModule from 'bitmovin-player/modules/bitmovinplayer-subtitles';
import PolyfillModule from 'bitmovin-player/modules/bitmovinplayer-polyfill';
import StyleModule from 'bitmovin-player/modules/bitmovinplayer-style';
import DashModule from 'bitmovin-player/modules/bitmovinplayer-dash';
import XmlModule from 'bitmovin-player/modules/bitmovinplayer-xml';

import {UIFactory} from 'bitmovin-player/bitmovinplayer-ui';
import 'bitmovin-player/bitmovinplayer-ui.css';


Player.addModule(EngineBitmovinModule);
Player.addModule(MseRendererModule);
Player.addModule(HlsModule);
Player.addModule(AbrModule);
Player.addModule(ContainerTSModule);
Player.addModule(SubtitlesModule);
Player.addModule(PolyfillModule);
Player.addModule(StyleModule);
Player.addModule(XmlModule);
Player.addModule(DashModule);


class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            player: [],
            progressive: null,
            poster: null
        };
    }

    async componentDidMount() {
        let {progressive, poster} = this.state;
        if (this.props.src !== null) {
            await this.setState({progressive: this.props.src, poster: imageBaseUrl + this.props.poster})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {player} = this.state;
        // player.load({
        //     hls: "https://live-technologies-vod.akamaized.net/cinematic/movies/mukhosh_manush/mukhosh_manush_full_movie/playlist.m3u8",
        //     poster: imageBaseUrl + this.props.poster
        // })
        // console.log(this.props.src)


        player.load({
            hls: this.props.src,
            poster: imageBaseUrl + this.props.poster
        });
        if (prevProps.src !== this.props.src) {
            // console.log(this.props.src)

        }
    }


    setupPlayer = () => {
        document.addEventListener('touchstart', null, true);
        let container = document.getElementById('player');
        const config = {
            // key: "94452f78-8dfd-4d3b-9b47-59cad553a664"
            key: "ade8ee74-e5df-493c-8fec-dfacaf22c007"
        };
        let player = new Player(container, config);
        UIFactory.buildDefaultUI(player);
        this.setState({player});
    };

    render() {
        // console.log(this.props.src)
        return (
            <div className="videoPlayer">
                <div id="player" ref={this.setupPlayer}/>
            </div>
        );
    }
}

export default VideoPlayer;