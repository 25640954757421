import React, {Component, Suspense} from 'react';
import Category from '../category/category';
import Loader from '../common/Loader';
import FeaturedCourse from '../featuredCourse/featuredCourse';
import DownloadApp from '../downloadApp/downloadApp';
import OurCourses from '../ourCourses/ourCourses';
import PriceTable from '../priceTable/priceTable';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import {connect} from "react-redux";
import {getSliders} from "../../redux/actions/slider";
import {withTranslation} from "react-i18next";
import {sendAccessInfo} from "../../redux/actions/accessLog";
import {loadState} from "../../LocalStore/localStorage";

const Banner = React.lazy(() => import('../banner/banner'));

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sliders: [],
        }
    }

    componentDidMount = async () => {
        const {sliders} = this.state;
        if (sliders && sliders.length < 1) {
            await this.props.dispatch(getSliders());
            await this.setState({sliders: this.props.sliders})
        }
        window.scrollTo(0, 0);
    };

    render() {
        const {sliders} = this.state;
        const {t} = this.props;
        return (
            <>
                <Suspense fallback={<Loader height={"556px"}/>}>
                    <Banner sliders={sliders} ln={t('ln')}/>
                </Suspense>
                <Category/>
                <FeaturedCourse/>
                <DownloadApp/>
                <OurCourses/>
                <PriceTable/>
                <OurClientSayAboutUs/>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    sliders: state.sliders,
});

export default withTranslation()(connect(mapStateToProps)(Home));