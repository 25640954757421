import React, {Component} from 'react';
import {Accordion} from 'react-bootstrap';
import FaqSingleItem from '../courseDetails/faqSingleItem';

class Faqs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            close: true,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    toggleClass = () => {
        this.setState({
            close: !this.state.close,
        });
    };

    render() {
        const {close} = this.state;

        return (
            <div className="privacyPolicy">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12 grayBg'>
                            <div className="privacyPolicyBody">
                                <h2 className="secTitle"> Frequently Asked Questions</h2>

                                <p>We're Here to Help. We value our relationship with you and strive to provide you with assistance and answers when you need it.</p>
                                
<br />
                                <div className="courseFaq">
                                    <Accordion defaultActiveKey="">
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'1'} title={`What is IQRA? `} />
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'2'} title={`How can a student learn? `} />
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'3'} title={`What is the process to learn? `} />
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'4'} title={`When can an individual learn from IQRA Platform? `} />
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'5'} title={`How this course will help me to learn qaidah?`} />
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'6'} title={`How i complete my payment?`} />
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'7'} title={`Can i just enroll into one course?`} />
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'8'} title={`How often is this course is special?`} />
                                        <FaqSingleItem close={close} toggleClass={this.toggleClass} id={'9'} title={`Can i read arabi letter after this course?`} />
                                    </Accordion>
                                </div>
                                <p>
                                    IQRA app as a Freemium app.This page is used to inform visitors regarding our
                                    policies
                                    with
                                    the collection, use, and disclosure of Personal Information if anyone decided to use
                                    our
                                    Service.
                                    </p>
                                <p>
                                    If you choose to use our Service, then you agree to the collection and use of
                                    information in
                                    relation to this policy. The Personal Information that we collect is used for
                                    providing
                                    and
                                </p>

                                <p>
                                    improving the Service. We will not use or share your information with anyone except
                                    as
                                    described in this Frequently Asked Questions.
                                </p>
                                <p>
                                    The terms used in this Frequently Asked Questions have the same meanings as in our
                                    Terms and
                                    Conditions,
                                    which is accessible at IQRA unless otherwise defined in this Frequently Asked
                                    Questions.
                                </p>
                                <p>
                                    <strong>Information Collection and Use</strong>
                                </p>

                                <p>
                                    For a better experience, while using our Service, we may require you to provide us
                                    with
                                    certain personally identifiable information, including but not limited to Device ID,
                                    Location, Storage, Firebase Authentication. The information that we request will be
                                    retained
                                    by us and used as described in this Frequently Asked Questions.
                                </p>
                                <p>
                                    The app does use third party services that may collect information used to identify
                                    you.
                                </p>
                                <p>
                                    Link to Frequently Asked Questions of third party service providers used by the
                                    app </p> <br/>
                                <ul type="disc">
                                    <li><a href="https://www.google.com/policies/privacy/" target="_blank">Google Play
                                        Services</a></li>
                                    <li><a href="https://firebase.google.com/policies/analytics" target="_blank">Firebase
                                        Analytics</a></li>
                                </ul>
                                <p><strong>Log Data</strong>
                                </p>
                                <p><br/>
                                    We want to inform you that whenever you use our Service, in a case of an error in
                                    the
                                    app we
                                    collect data and information (through third party products) on your phone called Log
                                    Data.
                                    This Log Data may include information such as your device Internet Protocol
                                    (&ldquo;IP&rdquo;) address, device name, operating system version, the configuration
                                    of
                                    the
                                    app when utilizing our Service, the time and date of your use of the Service, and
                                    other
                                    statistics.
                                </p>
                                <p><br/>
                                    <strong>Cookies</strong>
                                </p>
                                <p>
                                    Cookies are files with a small amount of data that are commonly used as anonymous
                                    unique
                                    identifiers. These are sent to your browser from the websites that you visit and are
                                    stored
                                    on your device's internal memory. <br/>
                                    This Service does not use these &ldquo;cookies&rdquo; explicitly. However, the app
                                    may
                                    use
                                    third party code and libraries that use &ldquo;cookies&rdquo; to collect information
                                    and
                                    improve their services. You have the option to either accept or refuse these cookies
                                    and
                                    know when a cookie is being sent to your device. If you choose to refuse our
                                    cookies,
                                    you
                                    may not be able to use some portions of this Service. </p> <br/>
                                <p><strong>Service Providers</strong>
                                </p>
                                <p><br/>
                                    We may employ third-party companies and individuals due to the following reasons:
                                </p>
                                <ul type="disc">
                                    <li>To facilitate our Service;</li>
                                    <li>To provide the Service on our behalf;</li>
                                    <li>To perform Service-related services; or</li>
                                    <li>To assist us in analyzing how our Service is used.</li>
                                </ul>
                                <br/>
                                <p>We want to inform users of this Service that these third parties have access to your
                                    Personal
                                    Information. The reason is to perform the tasks assigned to them on our behalf.
                                    However,
                                    they are obligated not to disclose or use the information for any other purpose.
                                </p>
                                <p><br/>
                                    <strong>Security</strong>
                                </p>
                                <p><br/>
                                    We value your trust in providing us your Personal Information, thus we are striving
                                    to
                                    use
                                    commercially acceptable means of protecting it. But remember that no method of
                                    transmission
                                    over the internet, or method of electronic storage is 100% secure and reliable, and
                                    we
                                    cannot guarantee its absolute security.
                                </p>
                                <p><br/>
                                    <strong>Links to Other Sites</strong>
                                </p>
                                <p><br/>
                                    This Service may contain links to other sites. If you click on a third-party link,
                                    you
                                    will
                                    be directed to that site. Note that these external sites are not operated by us.
                                    Therefore,
                                    we strongly advise you to review the Frequently Asked Questions of these websites.
                                    We have no
                                    control
                                    over and assume no responsibility for the content, privacy policies, or practices of
                                    any
                                    third-party sites or services.
                                </p>
                                <p><br/>
                                    <strong>Children&rsquo;s Privacy</strong>
                                </p>
                                <p><br/>
                                    These Services do not address anyone under the age of 13. We do not knowingly
                                    collect
                                    personally identifiable information from children under 13. In the case we discover
                                    that
                                    a
                                    child under 13 has provided us with personal information, we immediately delete this
                                    from
                                    our servers. If you are a parent or guardian and you are aware that your child has
                                    provided
                                    us with personal information, please contact us so that we will be able to do
                                    necessary
                                    actions.
                                </p>
                                <p><br/>
                                    <strong>Changes to This Frequently Asked Questions</strong>
                                </p>
                                <p><br/>
                                    We may update our Frequently Asked Questions from time to time. Thus, you are
                                    advised to review this
                                    page periodically for any changes. We will notify you of any changes by posting the
                                    new
                                    Frequently Asked Questions on this page. These changes are effective immediately
                                    after they are
                                    posted
                                    on this page.
                                </p>
                                <p><br/>
                                    <strong>Contact Us</strong>
                                </p>
                                <p><br/>
                                    If you have any questions or suggestions about our Frequently Asked Questions, do
                                    not hesitate to
                                    contact us at rajib@live-ent.net</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Faqs;
