import React, {Component} from 'react';
import BannerInner from '../banner/bannerInner';
import HadithLearningBgImg from "../../assets/static/img/banner/hadith_learning.png";
import FeaturedCourse from '../featuredCourse/featuredCourse';
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import HadithItem from './hadithItem';
import {connect} from "react-redux";
import {getHadith} from "../../redux/actions/hadith";
import {withTranslation} from "react-i18next";
import {sendAccessInfo} from "../../redux/actions/accessLog";

export class hadithLearning extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hadith: [],
            page: 1,
            pageSize: 8,
            itemCount: 0
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        const {page, pageSize, hadith} = this.state;
        if (hadith.length < 1) {
            await this.props.dispatch(getHadith(page, pageSize));
            const {count, rows} = this.props.hadith;
            await this.setState({hadith: rows, itemCount: count})
        }
    }

    pagination = async () => {
        const {page, pageSize, hadith} = this.state;
        await this.props.dispatch(getHadith(page + 1, pageSize + 8));
        const {rows} = this.props.hadith;
        if (this.props.hadith.length > 0) {
            await this.setState({hadith: hadith.concat(rows)});
        }
    };

    render() {
        const {hadith, itemCount} = this.state;
        const {t} = this.props;
        return (
            <div className="NamazLearningPageWrap">
                <BannerInner innerBannerBgImg={HadithLearningBgImg} title={t('HadithLearning')} />
                <HadithItem data={hadith} t={t}/>
                {hadith.length < itemCount &&
                <p className="text-center loadMore"> 
                    <button className="btn btn-primary" onClick={() => this.pagination()}>Load More</button>
                </p>
                }
                <FeaturedCourse/>
                <DownloadApp/>
                <OurClientSayAboutUs/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    hadith: state.hadith
});

export default withTranslation()(connect(mapStateToProps)(hadithLearning));
