import React from 'react';
import {dataNotFound} from "../../utils/Constants";

function DataNotFoundError({height, message}) {

    return (<div className="container">
        <div className="row" style={{height: height}}>
            <div className="col-4"></div>
            <div className="col-4">
                <img src={dataNotFound} alt=""/>
                <div className="alert alert-danger">
                    {message}
                </div>
            </div>
            <div className="col-4"></div>
        </div>
    </div>)
}

export default DataNotFoundError;