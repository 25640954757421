import {FETCH_MY_COURSE, FETCH_PROGRESS_REPORT} from "../index";
import http from "../../apis/service";
import {myCourse, progress, tuition} from "../../apis/endPoint";

export const getMyCourse = (id) => async dispatch => {
    const response = await http.get(`${myCourse}/${id}`);
    dispatch({
        type: FETCH_MY_COURSE,
        payload: response
    });
};

export const fullProgressReport = (tuition_config_id, student_id) => async dispatch => {
    const response = await http.postWithBody(`${tuition}/${progress}`, {tuition_config_id, student_id});
    dispatch({
        type: FETCH_PROGRESS_REPORT,
        payload: response
    });
};