import React, {Component} from 'react';
import courseFlowcartImg from "../../assets/static/img/course/course_shape.png";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

export class OurCourses extends Component {
    render() {
        const {t} = this.props;
        return (
            <div className="OurCoursesWrap">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="courseContent">
                                <h2 className="secTitle2 text-center">{t('OurApproachToBetterLearning')}</h2>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-col-sm-6 col-12 courseLeft text-right">
                                    <div className="courseSingleItem">
                                        <h4 className="courseTitle">{t('EveryLearnerIsUnique')}</h4>
                                        <p className="coutsePara">{t('WeOfferModuleBasedLearning')}</p>
                                    </div>
                                    <div className="courseSingleItem">
                                        <h4 className="courseTitle">{t('ContentsForBetterLearning')} </h4>
                                        <p className="coutsePara">{t('OurVideosContentsEnableLearners')}</p>
                                    </div>
                                    <div className="courseSingleItem">
                                        <h4 className="courseTitle">{t('StudentsExperienceAllRoundAcademicGrowth')}</h4>
                                        <p className="coutsePara">{t('OurFourModulesWorkSeamlessly')}</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-col-sm-6 col-12 courseCenter text-center mbHide">
                                    <div className="courseFlowcartImg">
                                        <img src={courseFlowcartImg} alt="img" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-col-sm-6 col-12 courseRight text-left">
                                    <div className="courseSingleItem">
                                        <h4 className="courseTitle">{t('LiveOnlineClasses')}</h4>
                                        <p className="coutsePara">{t('WeOfferLiveOneToOneAndGroupLearningClasses')}</p>
                                    </div>
                                    <div className="courseSingleItem">
                                        <h4 className="courseTitle">{t('HolisticLearningApproach')}</h4>
                                        <p className="coutsePara">{t('OurCustomizedExamModulesStepByStepHajjGuideAndIslamicLifestyle')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

export default withTranslation()(connect(mapStateToProps)(OurCourses));
