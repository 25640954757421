import crypto from 'crypto';

const algorithm = 'aes-256-ctr';
const key = 'b2df428b9929d3ace7c598bbf4e496b2';
const inputEncoding = 'utf8';
const outputEncoding = 'hex';


export default function decode(hash) {
    const textParts = hash.split(':');
    const IV = Buffer.from(textParts.shift(), outputEncoding);
    const encryptedText = Buffer.from(textParts.join(':'), outputEncoding);
    const decipher = crypto.createDecipheriv(algorithm, key, IV);
    let decrypted = decipher.update(encryptedText, outputEncoding, inputEncoding);
    decrypted += decipher.final(inputEncoding);

    return decrypted.toString();
}
