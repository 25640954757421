import ApiWebVone from "./apiWebVone";
import SubInfo from "./SubInfo";
import getMsisdn from "./getMsisdn";
import apiOnDemandSub from "./apiOnDemandSub";


function get(endPoint) {
    return ApiWebVone.get(endPoint)
        .then(res => {
            return res.data
        })
        .catch(err => {
            if (err.response) {
                return err.response;
            } else if (err.request) {
                return err.request;
            } else {
                return err.message;
            }
        });
}

function postWithBody(endPoint, body) {
    return ApiWebVone.post(endPoint,body)
        .then(res => {
            return res.data
        })
        .catch(err => {
            if (err.response) {
                return err.response;
            } else if (err.request) {
                return err.request;
            } else {
                return err.message;
            }
        });
}

function putWithFormData(endPoint, body) {
    return ApiWebVone.put(endPoint, body)
        .then(res => {
            return res.data
        })
        .catch(err => {
            if (err.response) {
                return err.response;
            } else if (err.request) {
                return err.request;
            } else {
                return err.message;
            }
        });
}

function subInfoPost(endPoint, body) {
    return SubInfo.post(endPoint, {"accessinfo":{"access_token": "K165S6V6q4C6G7H0y9C4f5W7t5YeC6","referenceCode":"20190827042622"}})
        .then(res => {
            return res.data
        })
        .catch(err => {
            if (err.response) {
                return err.response;
            } else if (err.request) {
                return err.request;
            } else {
                return err.message;
            }
        });
}

function getMsisdnProxy(endPoint) {
    return getMsisdn.get(endPoint)
        .then(res => {
            return res.data
        })
        .catch(err => {
            if (err.response) {
                return err.response;
            } else if (err.request) {
                return err.request;
            } else {
                return err.message;
            }
        });
}

function onDemandSub(endPoint) {
    return apiOnDemandSub.get(endPoint)
        .then(res => {
            return res.data
        })
        .catch(err => {
            if (err.response) {
                return err.response;
            } else if (err.request) {
                return err.request;
            } else {
                return err.message;
            }
        });
}

function deleteData(endPoint) {
    return ApiWebVone.delete(endPoint)
        .then(res => {
            return res.data
        })
        .catch(err => {
            if (err.response) {
                return err.response;
            } else if (err.request) {
                return err.request;
            } else {
                return err.message;
            }
        });
}

export default {
    get,
    postWithBody,
    putWithFormData,
    subInfoPost,
    getMsisdnProxy,
    onDemandSub,
    deleteData
}