import React from 'react';
import {Table} from 'react-bootstrap';
import SubscribedCourses from "./subscribedCourses";

const MyCoursesTable = ({myCourses}) => {

    return (
        <div className="myCourseTableWrap">
            <Table responsive>
                <tbody>
                {myCourses && myCourses.length > 0 && myCourses.map((item, index) =>
                    <SubscribedCourses key={index} item={item} now={78} index={index}/>
                )}
                </tbody>
            </Table>
        </div>
    );
}

export default MyCoursesTable;
