import {DELETE_ENROLLMENT, POST_ENROLLMENT} from "../index";
import http from "../../apis/service";
import {enrollment} from "../../apis/endPoint";

export const postEnroll = (tuition_config_id, student_id) => async dispatch => {
    const response = await http.postWithBody(`${enrollment}`, {
        tuition_config_id,
        student_id
    });
    dispatch({
        type: POST_ENROLLMENT,
        payload: response
    });

};

export const postEnrollDelete = (enroll_id) => async dispatch => {
    const response = await http.deleteData(`${enrollment}/${enroll_id}`);
    dispatch({
        type: DELETE_ENROLLMENT,
        payload: response
    });

};
