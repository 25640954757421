import {TODAY_TARABI} from "../index";
import http from "../../apis/service";
import {tarabi} from "../../apis/endPoint";

export const getTodayTarabi = () => async dispatch => {
    const response = await http.get(tarabi);
    dispatch({
        type: TODAY_TARABI,
        payload: response
    });
};