import React, {Component} from 'react';
import FeaturedCourse from '../featuredCourse/featuredCourse';
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import {getDigitalContents} from "../../redux/actions/digitalContents";
import {connect} from "react-redux";
import OneToOneChat from './oneToOneChat';

export class VideoCalling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            itemNumber: 10,
            contents: [],
            instructorId:null
        }
    }

    componentDidMount = async () => {
        const {pageNumber, itemNumber} = this.state;
        console.log()
        if (this.props.match.params.category && this.props.match.params.category !== "") {
            await this.props.dispatch(getDigitalContents(this.props.match.params.category, pageNumber, itemNumber));
            await this.setState({contents: this.props.digitalContents, instructorId:this.props.match.params.instructorId})
        }
        window.scrollTo(0, 0);
    };

    render() {
        const {instructorId} = this.state;
        return (
            <>
                <OneToOneChat instructorId={instructorId} />
                <FeaturedCourse/>
                <DownloadApp/>
                <OurClientSayAboutUs/>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    digitalContents: state.digitalContents,
});

export default connect(mapStateToProps)(VideoCalling);