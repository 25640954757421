import React from 'react';
import courseProfileFemale from "../../assets/static/svg/courseProfileFemale.svg";
import courseProfileMale from "../../assets/static/svg/courseProfileMale.svg";
import ProgressBar from 'react-bootstrap/ProgressBar'
import {ReactSVG} from 'react-svg';
import {Link} from 'react-router-dom';
import {progressReport, groupLearning} from '../../utils/routes';
import {imageBaseUrl} from "../../redux/apis/api";
import {loadState} from "../../LocalStore/localStorage";
import moment from "moment";


const SubscribedCourses = ({item, index}) => {
    return (
        <tr>
            <td>
                <div className="myCourseLogo">
                    <img src={imageBaseUrl + item.icon_url} alt="myCourseLogo"/>
                </div>
            </td>
            <td>
                <div className="liveRight">
                    <div className="liveNow">
                        <div className="live_icon">
                            <div className="inner"></div>
                        </div>
                    </div>
                </div>
                <div className="myCoursesDetails">
                    <h4 className="courseName">{item.tuition_en}</h4>
                    <p className="courseBy">By {item.instructor_first_name} {item.instructor_last_name} </p>
                    <p className="courseDuration"><span className="duration">Lecture Time: {item.duration}</span>
                        <span
                            className="qa">{moment(item.start_date).format('LL')} To {moment(item.end_date).format('LL')}</span>
                        <span className="qa">{item.available_days_in_week}</span>
                    </p>
                    <div className="courseTags">
                        <span className="courseTag">Module {1 + index}</span>
                    </div>
                    <div className="courseProgress">
                        <p className="progressCount text-right">{`${item.course_progress || 1}% Complete`}</p>
                        <ProgressBar/>
                    </div>
                </div>
                <div className="myCourseProfile">
                    <ReactSVG src={item.instructor_gender === 'male' ? courseProfileMale : courseProfileFemale}/>
                </div>
                <div className="progressFull">
                    <Link to={`${progressReport}/${item.student_id}/${item.tuition_config_id}`}
                          className="btnFullProgress">Full Progress</Link>
                    <Link to="#"
                          onClick={() => window.open(`https://test.iqra-live.com/?room=${item.instructor_id}-${loadState('uuid')}`, "mywindow", "menubar=1,resizable=1,width=1080,height=600")}
                          className="btnFullProgress">Go To Live</Link>
                </div>
            </td>
        </tr>
    );
}

export default SubscribedCourses;
