import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const LoaderSkeletonHadith = () => {
    return (
        <SkeletonTheme width={100} color="#f5f5f5" highlightColor="#EEE">
            <div className="primary-news-slider bg-white">
                <div className="slider_items">
                    <Skeleton/>
                    <div className="caption">
                        <Skeleton/>
                        <p className="description ell-line-2">
                            <Skeleton count={2}/>
                        </p>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    );
};

export default LoaderSkeletonHadith;