import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper, Polygon } from 'google-maps-react';

const style = {
    position: 'static',
    width: '100%',
    height: '100%'
}

const triangleCoords = [
    { lat: 25.774, lng: -80.190 },
    { lat: 18.466, lng: -66.118 },
    { lat: 32.321, lng: -64.757 },
    { lat: 25.774, lng: -80.190 }
];

export class ContactMap extends Component {
    state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
    };

    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };

    render() {


        return (
            <div className="ContactMap">
                <Map
                    google={this.props.google}
                    style={style}
                    zoom={15}
                    initialCenter={{
                        lat: 23.7506381,
                        lng: 90.3756008
                    }}
                >
                    <Polygon
                        paths={triangleCoords}
                        strokeColor="#0000FF"
                        strokeOpacity={0.8}
                        strokeWeight={2}
                        fillColor="#0000FF"
                        fillOpacity={0.35} />

                    <Marker onMouseover={this.onMarkerClick}
                        name={'Purple Digit Communication Ltd.'} />

                    <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}>
                        <div>
                            <h1>{this.state.selectedPlace.name}</h1>
                        </div>
                    </InfoWindow>
                </Map>
            </div >
        );
    }
}


export default GoogleApiWrapper({
    apiKey: ("AIzaSyAJaVM3ESMQJh7WOzhjZBFrLZ3bc7H2XZ8")
})(ContactMap)

{/* <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJaVM3ESMQJh7WOzhjZBFrLZ3bc7H2XZ8&callback=myMap"></script> */ }