import {FETCH_DIGITAL_CONTENT} from "../index";
import http from "../../apis/service";
import {digitalContent} from "../../apis/endPoint";

export const getDigitalContents = (category, pageNumber, items) => async dispatch => {
    const response = await http.get(digitalContent + `?category=${category}&page=${pageNumber}&pageSize=${items}`);
    dispatch({
        type: FETCH_DIGITAL_CONTENT,
        payload: response
    });

};