import React, {Component} from 'react';
import FeaturedCourse from '../featuredCourse/featuredCourse';
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getDigitalQuranContents} from "../../redux/actions/digitalQuran";
import MainBody from "./mainBody";
import {sendAccessInfo} from "../../redux/actions/accessLog";

export class DigitalQuranDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            itemNumber: 10,
            contents: [],
        };
    }

    componentDidMount = async () => {
        const {pageNumber, itemNumber} = this.state;
        await this.props.dispatch(getDigitalQuranContents(pageNumber, itemNumber));
        await this.setState({contents: this.props.digitalQuranContents});
        window.scrollTo(0, 0);
    };


    render() {
        const {contents} = this.state;
        const {t} = this.props;
        return (
            <>
                <MainBody contents={contents} ln={t('ln')}/>
                <FeaturedCourse/>
                <DownloadApp/>
                <OurClientSayAboutUs/>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    digitalQuranContents: state.digitalQuranContents,
});

export default withTranslation()(connect(mapStateToProps)(DigitalQuranDetail));