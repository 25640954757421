import http from "../../apis/service";
import {getMsisdn, login, sentOtp, student, studentInfo, subInfo} from "../../apis/endPoint";
import {FETCH_MSISDN, FETCH_OTP, FETCH_SUB_INFO, FETCH_USER_INFO, GET_SUB_INFO, UPDATE_PROFILE_IMAGE} from "../index";


export const getSubInfo = (msisdn = null) => async dispatch => {
    const response = await http.subInfoPost(`${subInfo}/${msisdn}`);
    dispatch({
        type: GET_SUB_INFO,
        payload: response
    });
};

export const getPhoneNumber = () => async dispatch => {
    const response = await http.get(`${getMsisdn}`);
    dispatch({
        type: FETCH_MSISDN,
        payload: response
    });
};
