import {FETCH_SCHEDULE_BY_TUITION_AND_GENDER, FETCH_SCHEDULE_DATE} from "../index";
import http from "../../apis/service";
import {schedule, scheduleDate, tuition} from "../../apis/endPoint";

export const getScheduleByTuitionAndGender = (tuition_id, gender) => async dispatch => {
    const response = await http.postWithBody(`${tuition}${schedule}`, {
        tuition_id,
        gender
    });
    dispatch({
        type: FETCH_SCHEDULE_BY_TUITION_AND_GENDER,
        payload: response
    });

};


export const getAvailableDate = (tuition_id, schedule_id) => async dispatch => {
    const response = await http.postWithBody(`${tuition}${scheduleDate}`, {
        tuition_id,
        schedule_id
    });
    dispatch({
        type: FETCH_SCHEDULE_DATE,
        payload: response
    });

};
