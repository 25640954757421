import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import {
    home,
    price,
    contact,
    featuredCourse,
    digitalClassRoom,
    contentDetails,
    courseDetails,
    digitalContent,
    login,
    hadithLearning,
    myCourses,
    progressReport,
    groupLearning,
    userProfile,
    quiz,
    notification,
    videoCalling,
    courseMaterial,
    digitalQuarn,
    privacyPolicy,
    faqs,
    liveStreaming,
    islamicLifeStyle,
    ramadanCalendar,
    tasbihClick, tarabi, ramadanForAirtel
} from "../utils/routes";


import "../assets/sass/style.scss";
import Header from './layouts/header/header';
import Home from "./home/home";
import Footer from './layouts/footer/footer';
import PricePage from './priceTable/pricePage';
import FeaturedPage from './featuredCourse/featuredCourses';
import DigitalClassRoom from './featuredCourse/digitalClassRoom';
import ContactPage from './contact/ContactPage';
import featuredCoursePage from './featuredCourse/featuredCoursePage';
import ContentDetails from "./contentDetails/contentDetails";
import CourseDetailsPages from './courseDetails/CourseDetailsPages';
import DigitalContent from "./digitalContent/DigitalContent";
import HadithLearning from './hadith/hadithLearning';
import HadithDetails from './hadith/hadithDetails';
import IslamicLifeStyle from './islamicLifeStyle/islamicLifeStyle';
import Login from "./auth/Login";
import MyCourses from './myCourses/myCourses';
import MyCourseProgressReport from './myCourseProgressReport/myCourseProgressReport';
import LiveInstructorList from './groupLearning/liveInstructor';
import UserProfile from './userProfile/userProfile';
import Quiz from './quiz/quiz';
import Notification from './notification/notification';
import {VideoCalling} from './videoChat/videoCalling';
import CourseMaterialDetail from "./courseMaterial/courseMaterialDetail";
import DigitalQuranDetail from "./digitalQuran/digitalQuranDetail";
import PrivacyPolicy from './privacyPolicy/privacyPolicy';
import Faqs from './faqs/faqs';
import {LiveStream} from "./groupLearning/liveStreeming";
import RamadanCalendar from './islamicLifeStyle/RamadanCalendar';
import TasbihClick from './tasbih/tasbihClick';
// import Tarabi from "./tarabi/tarabi";
import RamadanForAirtel from './islamicLifeStyle/RamadanForAirtel';
import Tarabi from "./tarabi/tarabi";
import Admin from './Admin';

class App extends Component {

    componentDidMount() {
        if (window.location.pathname === '/appDpSub' || window.location.pathname === '/appDpHome' || window.location.pathname === '/appDpDigitalContent' || window.location.pathname === '/appDpHajjContent' || window.location.pathname === '/appDpDigitalClassRoom') {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.purpledigit.iqra&hl=en';
        }
    }

    render() {
        return (
            <div className="App">
                {window.location.pathname !== ramadanForAirtel && window.location.pathname !== '/admin' && window.location.pathname!== privacyPolicy &&
                <Header/>
                }
                <Switch>
                    <Route path={"/"} component={Home} exact></Route>
                    <Route path={login} component={Login} exact></Route>
                    <Route path={home} component={Home} exact></Route>
                    <Route path={price} component={PricePage} exact></Route>
                    <Route path={contact} component={ContactPage} exact></Route>
                    <Route path={`${featuredCourse}/:contentType/:age`} component={featuredCoursePage} exact></Route>
                    <Route path={featuredCourse} component={FeaturedPage} exact></Route>
                    <Route path={digitalClassRoom} component={DigitalClassRoom} exact></Route>
                    <Route path={`${courseDetails}/:id`} component={CourseDetailsPages} exact></Route>
                    <Route path={digitalContent} component={DigitalContent} exact></Route>
                    <Route path={digitalQuarn} component={DigitalQuranDetail} exact></Route>
                    <Route path={hadithLearning} component={HadithLearning} exact></Route>
                    <Route path={`${hadithLearning}/:id`} component={HadithDetails} exact></Route>
                    <Route path={`${contentDetails}/:category`} component={ContentDetails} exact></Route>
                    <Route path={myCourses} component={MyCourses} exact></Route>
                    <Route path={`${progressReport}/:studentId/:tuition_config_id`} component={MyCourseProgressReport}
                           exact></Route>
                    <Route path={groupLearning} component={LiveInstructorList} exact></Route>
                    <Route path={liveStreaming} component={LiveStream} exact></Route>
                    <Route path={userProfile} component={UserProfile} exact></Route>
                    <Route path={quiz} component={Quiz} exact></Route>
                    <Route path={notification} component={Notification} exact></Route>
                    <Route path={`${videoCalling}/:instructorId`} component={VideoCalling} exact></Route>
                    <Route path={courseMaterial} component={CourseMaterialDetail} exact></Route>
                    <Route path={privacyPolicy} component={PrivacyPolicy} exact></Route>
                    <Route path={faqs} component={Faqs} exact></Route>
                    <Route path={islamicLifeStyle} component={IslamicLifeStyle} exact></Route>
                    <Route path={ramadanCalendar} component={RamadanCalendar} exact></Route>
                    <Route path={'/admin'} component={Admin} exact></Route>
                    <Route path={tasbihClick} component={TasbihClick} exact></Route>
                    <Route path={tarabi} component={Tarabi} exact></Route>
                    <Route path={ramadanForAirtel} component={RamadanForAirtel} exact></Route>
                </Switch>

                {window.location.pathname !== ramadanForAirtel && window.location.pathname !== '/admin' && window.location.pathname!== privacyPolicy &&
                <Footer/>
                }
            </div>
        );
    }
}

export default App;
