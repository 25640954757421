import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { avater } from "../../utils/Constants";
import defaultImgSetup from "../../redux/helper/defaultImgSetup";


// const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

class ProfileImageUpdateReduxForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            buttonStatus: false,
            imgSrc: null,
            isComplete: 0,

        }
    }

    adaptFileEventToValue = delegate => e => {
        delegate(e.target.files[0]);

        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();

            reader.addEventListener('load', () =>
                // this.setState({imgSrc: e.target.files[0]});
                this.setState({ imgSrc: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    clearImgSrc = () => {
        this.setState({ imgSrc: "" })
    };

    renderFileInput = ({ input: { value: omitValue, onChange, onBlur, ...inputProps }, meta, ...props }) => {
        return (
            <>
                <input
                    onChange={this.adaptFileEventToValue(onChange)}
                    onBlur={this.adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                />
                {meta && meta.invalid && meta.error && (
                    <span className="errorNote"> {meta.error}</span>
                )}
            </>

        );
    };

    onSubmit = formValues => {
        this.props.onSubmit(formValues);
    };

    render() {
        const { imgSrc } = this.state;
        const { userInfo } = this.props;


        return (
            <div className="post-share">
                <form onSubmit={this.props.handleSubmit(this.onSubmit)} encType="multipart/form-data">

                    <div className="userProfilePic text-center">
                        {
                            imgSrc &&
                            <img src={imgSrc || avater} alt="user" className="img-fluid rounded-circle" />
                        }

                        {!imgSrc &&
                            <img src={defaultImgSetup(userInfo.profile_pic)} alt="userProfuserilePic" className="img-fluid rounded-circle" />
                        }

                        <div className="upProfileImg">
                            <Field
                                name="profile_pic"
                                type="file"
                                component={this.renderFileInput}
                                id="uploadProfileImg"
                            />
                            <label htmlFor="uploadProfileImg">
                                <i className="fa fa-camera"></i>
                            </label>
                        </div>
                    </div>
                    <div className="saveBtn text-center">
                        <button type="submit" className="btn post-btn btnSave">Save</button>
                    </div>
                </form>
            </div>
        )
    }
}

const validate = formValues => {
    const errors = {};

    if (!formValues.profile_pic) {
    }

    if (formValues.profile_pic) {
        let imgTypes = [
            "image/jpeg",
            "image/png",
            "image/jpg"
        ];

        //console.log(formValues.profile_pic.type);
        if (!imgTypes.includes(formValues.profile_pic.type)) {
            errors.profile_pic = 'Profile image must be  .JPG or .JPEG or .PNG file';
            alert('Profile image must be .JPG or .JPEG or .PNG file');
        }
    }
    return errors;
};

export default reduxForm({
    form: 'profileImageUpdateReduxForm',
    validate
})(ProfileImageUpdateReduxForm);