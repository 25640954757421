import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import React from "react";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            "home": "Home",
            "ln": "বাংলা",
            "featuredCourses": "Featured Courses",
            "pricing": "Pricing",
            "myCourse": "My Course",
            "Course": "Course",
            "contact": "Contact",
            "aboutUs": "About Us",
            "ExpertScholars": "Expert Scholars",
            "Help": "Help",
            "PrivacyPolicy": "Privacy Policy",
            "T_247": "24/7",
            "TermsOfService": "Terms of Service",
            "feature": "Features",
            "y2020": "2020",
            "Duration": "Duration",
            "PurpleDigitCommunicationLtd": "Purple Digit Communication Ltd.",
            "AllRightsReserved": "All rights reserved.",
            "findOnUs": "Find on us:",
            "CheckoutAllCategories": "Checkout All Categories",
            "LearnWhatYouNeed": "Learn What You Need",
            "IQRAisAnInclusiveLearningPlatform": "IQRA is an inclusive learning platform that matches the student’s learning needs though digital classroom, digital contents, Hajj Contents and Islamic lifestyle.",
            "StudentsSreViewing": "Students are viewing",
            "DigitalClassRoom": "Digital Classroom",
            "DigitalContent": "Digital Content",
            "HajjContent": "Hajj Content",
            "islamicHistory": "Islamic History",
            "IslamicLifeStyle": "Islamic Lifestyle",
            "TimeOfTarabiNamaz": "Live Tarabi Prayer",            
            "OhirAlo": "Ohir Alo", 
            "DigitalTasbih": "Digital Tasbih",
            "RamadanSehriAndIftarTime2020": "Ramadan Sehri & Iftar Time 2020",
            "courseMaterial": "Course Material",
            "DigitalQuran": "Digital Quran",
            "namajLearning": "Namaj Learning",
            "HadithLearning": "Hadith Learning",
            "DownloadTheIQRAAPP": "Download the IQRA APP",
            "IncreaseYourIslamic": "Increase your Islamic Knowledge form IQRA ! IQRA  APP will connect you with best scholars in Bangladesh!",
            "IQRAIsAnAuthenticEdTechPlatform": "IQRA is an authentic EdTech Platform where people from all age group can learn the Holy Quran in a scientific manner.",
            "HereAtIQRA": "Here at IQRA, we believe in preaching and spreading “Islam”. We want to create a community where people will be able to access to “Islamic Knowledge” from any part of the world. Languages creates barriers in many circumstances, thus we have designed our platform in a manner that diminishes this barrier as well. IQRA plans in working with its full potential for the people and has a dream of spreading happiness among all of its learners.",
            "StayWithIqra": "Stay with Iqra",
            "LearnFromBangladeshBestIslamicScholars": "Learn From Bangladesh Best Islamic Scholars",
            "WeBelieveThatEveryStudentLearnsDifferently": "We believe that every student learns differently and we work towards helping them learn better We use adaptive algorithms to create individual learning paths that improve.",
            "OurApproachToBetterLearning": "Our Approach to Better Learning",
            "EveryLearnerIsUnique": "Every Learner is Unique",
            "WeOfferModuleBasedLearning": "We offer module-based learning process for learners with different needs and all age groups",
            "LiveOnlineClasses": "Live Online Classes",
            "WeOfferLiveOne-to-OneAndGroupLearningClasses": "We offer live One-to-One and Group learning classes by expert male and female Islamic Scholars ",
            "ContentsForBetterLearning": "Contents for Better Learning",
            "OurVideosContentsEnableLearners": "Our videos contents enable learners to practice at their own pace from any convenient place and time",
            "HolisticLearningApproach ": "Holistic Learning Approach ",
            "OurCustomizedExamModulesStepByStepHajjGuideAndIslamicLifestyle": "Our customized exam modules, step-by-step Hajj guide and Islamic Lifestyle contents enables learners to maintain a conscious lifestyle.",
            "StudentsExperienceAllRoundAcademicGrowth": "Students experience all-round academic growth",
            "OurFourModulesWorkSeamlessly": "Our four modules work seamlessly to boost every student’s all-round academic growth, helping them",
            "free": "FREE",
            "PricingTable": "Pricing Table",
            "zero": "0",
            "seven": "7",
            "ninety": "90",
            "fifty": "50",
            "daily": "Daily",
            "weekly": "Weekly",
            "Monthly": "Monthly",
            "ON_DEMAND": "ON DEMAND ",
            "AccessToLimitedVideoContents": "Access to Limited Video contents",
            "NamajLearningContents": "Namaj Learning Contents",
            "alhamdulillah": "Alhamdulillah",
            "GetStarted": "Get Started",
            "SUBSCRIPTION": "SUBSCRIPTION",
            "DigitalClassroomGroupLearning": "Digital classroom - Group Learning",
            "AccessToAllDigitalVideoContents": "Access to all digital video contents",
            "AccessToHajjContents": "Access to Hajj Contents",
            "IVRContents": "IVR contents",
            "ModuleBasedQuiz": "Module-based quiz",
            "ModuleCompletionExam": "Module completion exam",
            "DigitalCertificates": "Digital certificates",
            "DownloadToModuleBasedDigitalBooks": "Download to module-based digital books",
            "CanConnectOneToOne": "Can connect one to one with our any scholar",
            "OneToOne": "One to One",
            "GroupLearning": "Group Learning",
            "QuizSessions": "Quiz Session",
            "largestCoursesSelections": "largest courses selections",
            "ChooseFromOver100000OnlineVideo": "Choose from over 100,000 online video courses with new additions published every month",
            "Childhood": "Childhood",
            "teen": "Teen",
            "Adult": "Adult",
            "SeniorCitizen": "Senior Citizen",
            "OfferedBy": "Offered By",
            "WhyChoseThisCourse": "Why chose this course",
            "Recognition": "Recognition",
            "RecognizingTheArabicAlphabets": "Recognizing the Arabic alphabets and symbols, understanding the basic rules of pronunciation and connecting the alphabets.",
            "ThisCourseIncludes":"This Course Includes",
            "FullLifetimeAccess": "Full lifetime access",
            "AccessOnMobileAndPC": "Access on mobile and PC",
            "CertificateOfCompletion": "Certificate of Completion",
            "onDemandVideo": "on-demand video",
            "Male": "Male",
            "Female": "Female",
            "Gender": "Gender",
            "AvailableSlot": "Available Slot",
            "AvailableDate": "Available Date",
            "SkillsYouWillGain": "Skills you will gain",
            "EnrollNow": "Enrol Now",
            "ThereAreNoAvailableSlotsForThisCourse": "There are no available slots for this Course.",
            "OhUserYouGotWarning": "Oh, User! You got a Warning!",
        }
    },
    bn: {
        translation: {
            "home": "হোম",
            "ln": "English",
            "featuredCourses": "বৈশিষ্ট্যযুক্ত কোর্স",
            "pricing": "মূল্য স্থির",
            "myCourse": "আমার কোর্স",
            "Course": "কোর্স",
            "contact": "যোগাযোগ",
            "aboutUs": "আমাদের সম্পর্কে",
            "ExpertScholars": "বিশেষজ্ঞ পণ্ডিতগণ",
            "Help": "সাহায্য",
            "PrivacyPolicy": "গোপনীয়তা নীতি",
            "T_247": "২৪/৭",
            "TermsOfService": "সেবা পাবার শর্ত",
            "feature": "বৈশিষ্ট্যযুক্ত",
            "y2020": "২০২০",
            "Duration": "ব্যাপ্তিকাল",
            "PurpleDigitCommunicationLtd": "পারপেল ডিজিট কমিউনিকেশন লিমিটেড ।",
            "AllRightsReserved": "সমস্ত অধিকার সংরক্ষিত ।",
            "findOnUs": "আমাদের সন্ধান করুনঃ",
            "CheckoutAllCategories": "সমস্ত ক্যাটাগরি দেখুন",
            "LearnWhatYouNeed": "আপনার যা প্রয়োজন তা শিখুন",
            "IQRAisAnInclusiveLearningPlatform": "ইকরা একটি অন্তর্ভুক্তিমূলক শিক্ষাবিষয়ক প্ল্যাটফর্ম যা শিক্ষার্থীর শেখার প্রয়োজনীয়তার সাথে মেলে ডিজিটাল শ্রেণিকক্ষ, ডিজিটাল কন্টেন্ট, হজ কন্টেন্ট এবং ইসলামী জীবনধারা ।",
            "StudentsSreViewing": "শিক্ষার্থীরা দেখছে",
            "DigitalClassRoom": "ডিজিটাল শ্রেণিকক্ষ",
            "DigitalContent": "ডিজিটাল কন্টেন্ট",
            "HajjContent": "হাজ্জ কন্টেন্ট",
            "islamicHistory": "ইসলামিক হিস্ট্রি",
            "courseMaterial": "কোর্সের উপকরণ",
            "DigitalQuran": "ডিজিটাল কোরআন",
            "namajLearning": "নামাজ শিক্ষা",
            "HadithLearning": "হাদিস শিক্ষা",
            "IslamicLifeStyle": "ইসলামিক লাইফ স্টাইল",
            "TimeOfTarabiNamaz": "লাইভ তারাবী নামাজ",            
            "OhirAlo": "ওহীর আলো",
            "DigitalTasbih": "ডিজিটাল তাসবিহ",
            "RamadanSehriAndIftarTime2020": "রমজান ইফতার এবং সেহরি",
            "DownloadTheIQRAAPP": "ইকরা অ্যাপ্লিকেশনটি ডাউনলোড করুন",
            "IncreaseYourIslamic": "ইকারিয়ায় ইসলামিক জ্ঞান ফরম! ইকরা আপার আপনাকে বাংলাদেশের সেরা বিক্রেতাদের সাথে সংযুক্ত করবে!",
            "IQRAIsAnAuthenticEdTechPlatform": "ইকরা একটি নির্ভরযোগ্য এডটেক প্ল্যাটফর্ম যেখানে যেকোন বয়সী মানুষই বিজ্ঞানভিত্তিক পদ্ধতিতে পবিত্র কুরআন শিখতে পারে।",
            "HereAtIQRA": "ইকরায় আমরা ইসলামের প্রচার ও প্রসারে বিশ্বাস করি। আমরা এমন একটি কমিউনিটি তৈরী করতে চাই যেখানে ইসলামি জ্ঞান পৃথিবীর যে কোন প্রান্ত থেকে অ্যাএক্সেস করা যাবে। এই প্ল্যাটফর্মটি এমনভাবে ডিজাইন করা হয়েছে যে ভাষাগত কোন সমস্যাও হবে না। ইকরা তাদের সম্পূর্ন সম্ভাব্যতা ব্যবহার করে এবং শিক্ষার্থিদের খুশী করতে বদ্ধ পরিকর।",
            "StayWithIqra": "ইকরার সঙ্গে থাকুন",
            "LearnFromBangladeshBestIslamicScholars": "বাংলাদেশের সেরা ইসলামিক স্কলারদের কাছ থেকে শিখুন",
            "WeBelieveThatEveryStudentLearnsDifferently": "আমরা বিশ্বাস করি যে প্রতিটি শিক্ষার্থী আলাদাভাবে শেখে এবং আমরা তাদের আরও ভালভাবে শিখতে সহায়তা করার দিকে কাজ করি আমরা পৃথক শিক্ষার পথগুলি উন্নত করার জন্য অভিযোজিত অ্যালগরিদম ব্যবহার করি।",
            "OurApproachToBetterLearning": "উন্নত শিক্ষনের জন্য আমাদের প্রচেষ্টা ",
            "EveryLearnerIsUnique": "প্রতিটি শিক্ষার্থীই অনন্য/আলাদা",
            "WeOfferModuleBasedLearning": "শিক্ষার্থীদের প্রয়োজন ও বয়সের ভিত্তিতে আমরা ভিন্ন ভিন্ন শিক্ষণ পদ্ধতি ব্যবহার করে থাকি।",
            "LiveOnlineClasses": "সরাসরি অনলাইন ক্লাস",
            "WeOfferLiveOneToOneAndGroupLearningClasses": "দলভিত্তিক এবং প্রতিটি শিক্ষার্থীর জন্য আলাদা আলাদা বিশেষজ্ঞ পুরুষ এবং মহিলা শিক্ষকের মাধ্যমে সরাসরি ক্লাস নেয়া হয়।",
            "ContentsForBetterLearning": "সুশিক্ষনের জন্য কন্টেন্ট",
            "OurVideosContentsEnableLearners": "একজন শিক্ষার্থী আমাদের ভিডিও কন্টেন্ট থেকে তাদের নিজেদের সুবিধাজনক সময় ও স্থান অনুযায়ী অনুশীলন করতে পারে।",
            "HolisticLearningApproach": "হলিস্টিক শিক্ষণ পদ্ধতি",
            "OurCustomizedExamModulesStepByStepHajjGuideAndIslamicLifestyle": "আমাদের কাস্টমাইজড পরীক্ষা পদ্ধতি, ক্রমানুসারে তৈরী হজ্ব গাইড এবং ইসলামিক জীবন পদ্ধতি সচেতন জীবনযাপনে সহায়তা করে থাকে।",
            "StudentsExperienceAllRoundAcademicGrowth": "শিক্ষার্থীরা চতুর্দিকে একাডেমিক বৃদ্ধির অভিজ্ঞতা অর্জন করে",
            "OurFourModulesWorkSeamlessly": "আমাদের চারটি মডিউল প্রতিটি শিক্ষার্থীর চতুর্থ একাডেমিক বৃদ্ধি বাড়াতে, তাদের সহায়তা করার জন্য নির্বিঘ্নে কাজ করে",
            "free": "ফ্রী",
            "PricingTable": "মূল্য তালিকা",
            "zero": "০",
            "seven": "৭",
            "ninety": "৯০",
            "fifty": "৫০",
            "daily": "দৈনন্দিন",
            "weekly": "সাপ্তাহিক",
            "Monthly": "মাসিক",
            "ON_DEMAND": "চাহিদা সাপেক্ষে",
            "AccessToLimitedVideoContents": "সীমিত ভিডিও সামগ্রীতে অ্যাক্সেস",
            "NamajLearningContents": "নামাজ শিখার বিষয়বস্তু",
            "alhamdulillah": "আলহামদুলিল্লাহ",
            "GetStarted": "এবার শুরু করা যাক",
            "SUBSCRIPTION": "গ্রাহক",
            "DigitalClassroomGroupLearning": "ডিজিটাল ক্লাসরুম - গ্রুপ লার্নিং",
            "AccessToAllDigitalVideoContents": "সমস্ত ডিজিটাল ভিডিও সামগ্রীতে অ্যাক্সেস",
            "AccessToHajjContents": "সমস্ত ডিজিটাল ভিডিও সামগ্রীতে অ্যাক্সেস",
            "IVRContents": "IVR সামগ্রী",
            "ModuleBasedQuiz": "মডিউল ভিত্তিক কুইজ",
            "ModuleCompletionExam": "মডিউল ভিত্তিক কুইজ",
            "DigitalCertificates": "ডিজিটাল শংসাপত্র",
            "DownloadToModuleBasedDigitalBooks": "মডিউল ভিত্তিক ডিজিটাল বইগুলিতে ডাউনলোড করুন",
            "CanConnectOneToOne": "আমাদের যে কোনও শিক্ষকের সাথে একজনকে যুক্ত করতে পারেন",
            "OneToOne": "ওয়ান টু ওয়ান",
            "GroupLearning": "গ্রুপ ক্লাস",
            "QuizSessions": "কুইজ সেশন",
            "largestCoursesSelections": "বৃহত্তম কোর্স নির্বাচন",
            "ChooseFromOver100000OnlineVideo": "প্রতি মাসে প্রকাশিত নতুন সংযোজন সহ 100,000 এর বেশি অনলাইন ভিডিও কোর্স থেকে চয়ন করুন",
            "Childhood": "শৈশব",
            "teen": "কিশোর",
            "Adult": "প্রাপ্তবয়স্ক",
            "SeniorCitizen": "জ্যেষ্ঠ নাগরিক",
            "OfferedBy": "প্রদত্ত",
            "WhyChoseThisCourse": "কেন এই কোর্সটি বেছে নিন",
            "Recognition": "স্বীকার",
            "RecognizingTheArabicAlphabets": "আরবি বর্ণমালা এবং চিহ্নগুলি সনাক্ত করা, উচ্চারণের প্রাথমিক নিয়মগুলি বোঝা এবং বর্ণমালা সংযোগ করা।",
            "FullLifetimeAccess": "আজীবন অ্যাক্সেস",
            "AccessOnMobileAndPC": "মোবাইল এবং পিসিতে অ্যাক্সেস",
            "CertificateOfCompletion": "শেষ করার প্রমাণপত্র",
            "onDemandVideo": "অন ডিমান্ড ভিডিও",
            "Male": "পুরুষ",
            "Female": "মহিলা",
            "Gender": "লিঙ্গ",
            "AvailableSlot": "উপলব্ধ স্লট",
            "AvailableDate": "উপলব্ধ তারিখ",
            "SkillsYouWillGain": "দক্ষতা আপনি অর্জন করবে",
            "EnrollNow": "এখনই নথিভুক্ত করুন",
            "ThisCourseIncludes":"এই কোর্স অন্তর্ভুক্ত",
            "ThereAreNoAvailableSlotsForThisCourse": "এই কোর্সের জন্য কোন স্লট উপলব্ধ নেই ।",
            "OhUserYouGotWarning": "ওহ! আপনি একটি সতর্কতা পেয়েছি!",

        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;