import React from 'react'
import { ReactSVG } from "react-svg";


const WhyChoseItem = ({ icon, title, discripTion }) => {
    return (

        <div className="col-sm-6 col-12 whyChoseSingleItem">
            <div className="itemTop">
                <h5>
                    <span className="icon">
                        <ReactSVG src={icon} />
                    </span>
                    {title}
                </h5>
            </div>
            <div className="itemDetails">
                <p>{discripTion}</p>
            </div>
        </div>
    )
}

export default WhyChoseItem
