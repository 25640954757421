import React from 'react'
import { ReactSVG } from "react-svg";
import ContactSingleItem from './contactSingleItem';

function ContactRequest() {
    return (
        <div className="conatctWprap">
            <div className="contactRequest">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="contactRequestTop">
                                <h4 className="text-center secTitle2">Submit Your Request</h4>
                                <p className="text-center contactPara">We are always ready to help you to learn quran, hope you enjoy exploring all the resources </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ContactSingleItem icon={"fa fa-mobile"}  title={"Call Us"} contactInfo={"+88-01XXX-XXXXXX"} />
                        <ContactSingleItem icon={"fa fa-map-marker"}  title={"Visit Us"} contactInfo={"Dhanmondi"} />
                        <ContactSingleItem icon={"fa fa-envelope-o"}  title={"Mail Us"} contactInfo={"help@iqra-live.com"} />
                        <ContactSingleItem icon={"fa fa-headphones"}  title={"Live Chat"} contactInfo={"Chat with Us 24/7"} />
                    </div>
                </div>
            </div>
            <div className="contactFormWrap">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <form action="#" className="contactForm">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="fieldName">Hi, what can we help you with?</label>
                                            <select className="form-control">
                                                <option value="0">General</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="fieldName">Tel us more *</label>
                                            <select className="form-control">
                                                <option value="0">Getting Started</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="fieldName">Your Name *</label>
                                            <input type="text" placeholder="Your Name" className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="fieldName">Your email *</label>
                                            <input type="text" placeholder="Email Address" className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="fieldName">Your Website Link </label>
                                            <input type="url" placeholder="https://" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="fieldName">Attachments </label>
                                            <input type="file" id="Attachments" />
                                            <label htmlFor="Attachments" className="attachment form-control">Upload your file</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="fieldName">Provide a detailed description</label>
                                            <textarea type="url" placeholder="How can we help you?" className="form-control" required></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <p className="note">Please enter the details of your request. A member of our support staff will respond as soon as possible.</p>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="btnGroup">
                                            <button className="btnSubmit" type="submit">Submit Request</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactRequest
