import React, {Suspense} from 'react'
import FeaturedTop from '../featuredCourse/featuredTop';
import Fajr_namaj from "../../assets/static/svg/Fajr_namaj.svg";
import LoaderSkeletonHadith from "../common/LoaderSkeletonHadith";
const HadithSingleItem = React.lazy(() => import('./hadithSingleItem'));


function HadithItem({data, t}) {
    return (
        <div className="digitalQuarnItem">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <FeaturedTop
                            title={t('LearnFromBangladeshBestIslamicScholars')}
                            para={t('WeBelieveThatEveryStudentLearnsDifferently')}
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    {data && data.length > 0 && data.map((item, i) =>
                        <Suspense fallback={<LoaderSkeletonHadith/>} key={i}>
                            <HadithSingleItem icon={Fajr_namaj} item={item} t={t} ln={t('ln')}/>
                        </Suspense>
                    )}
                </div>
            </div>
        </div>
    )
}

export default HadithItem
