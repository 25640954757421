import React, {Component} from 'react';
import FeaturedCourse from '../featuredCourse/featuredCourse';
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import StremingMody from "./StremingMody";

export class LiveStream extends Component {

    render() {
        return (
            <>
                <StremingMody src={this.props.location.state.src}/>
                <FeaturedCourse/>
                <DownloadApp/>
                <OurClientSayAboutUs/>
            </>
        );
    }
}

const mapStateToProps = (state) => ({});

export default withTranslation()(connect(mapStateToProps)(LiveStream));