import {FETCH_SLIDER} from "../index";
import http from "../../apis/service";
import {slider} from "../../apis/endPoint";

export const getSliders = () => async dispatch => {
    const response = await http.get(slider);
    dispatch({
        type: FETCH_SLIDER,
        payload: response
    });

};