import React, {Component} from 'react';
import sepImg from "../../assets/static/img/border.png";
import DigitalClassroomIcon1 from "../../assets/static/svg/Digitall_classroom_website_icon_01.svg";
import DigitalContentIcon01 from "../../assets/static/svg/Digital_content_website_icon_01.svg";
import HajjContentIcon01 from "../../assets/static/svg/Hajj_content_website_icon_01.svg";
import IslamicLifeStyleIcon01 from "../../assets/static/svg/Islamic_lifestyle_website_icon_01.svg";
import {digitalClassRoom, contentDetails, islamicLifeStyle} from '../../utils/routes';
import CategoryBox from "../common/categoryBox";
import {hajjContent} from "../../utils/Constants";
import { digitalContent } from '../../redux/apis/endPoint';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

export class Category extends Component {
    render() {
        const {t} = this.props;

        return (
            <div className="categaryWrap" id="landingFeatured">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="categoryLeftContent">
                                <h5 className="secTopTitle">{t('CheckoutAllCategories')}</h5>
                                <h2 className="catTtitle">{t('LearnWhatYouNeed')}</h2>
                                <div className="sepImg"><img src={sepImg} alt="img-border"/></div>
                                <p className="catPara">{t('IQRAisAnInclusiveLearningPlatform')}</p>
                                {/*<div className="joinBox">*/}
                                {/*    <form action="#" className="joinForm">*/}
                                {/*        <div className="selectCountryCode">*/}
                                {/*            <option value="0">+88</option>*/}
                                {/*        </div>*/}
                                {/*        <div className="joinInputBox">*/}
                                {/*            <input type="text" step="1" min="0" className="joinInput"*/}
                                {/*                   placeholder="Phone Number"/>*/}
                                {/*            <button className="joinBtn">JOIN NOW</button>*/}
                                {/*        </div>*/}
                                {/*    </form>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="row">
                                <CategoryBox icon={DigitalClassroomIcon1} title={t('DigitalClassRoom')}
                                             colorFill={'digitalClassroom'} reLink={digitalClassRoom}/>
                                <CategoryBox icon={DigitalContentIcon01} title={t('DigitalContent')}
                                             colorFill={'digitalContent'}
                                             reLink={digitalContent}/>
                                <CategoryBox icon={HajjContentIcon01} title={t('HajjContent')} colorFill={'hajjContents'}
                                             reLink={`${contentDetails}/${hajjContent}`}/>
                                <CategoryBox icon={IslamicLifeStyleIcon01} title={t('IslamicLifeStyle')}
                                             colorFill={'islamicLifestyle'}
                                             reLink={islamicLifeStyle}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    localization: state.localizationReducer,
});

export default withTranslation()(connect(mapStateToProps)(Category));
