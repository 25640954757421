import React, { Component } from 'react';
import BannerInner from '../banner/bannerInner';
import innerBannerBgImg from "../../assets/static/img/banner/hadith_learning.png";
import DownloadApp from '../downloadApp/downloadApp';
import OurClientSayAboutUs from '../ourClient/ourClientSayAboutUs';
import QuizSingleBody from './quizSingleBody';

export class Quiz extends Component {
    
    async componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="quizWrap">
                <BannerInner innerBannerBgImg={innerBannerBgImg} title={"IQRA Quiz"} />
                <QuizSingleBody />
                <DownloadApp />
                <OurClientSayAboutUs />
            </div>
        );
    }
}

export default Quiz;
