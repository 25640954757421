import {FETCH_COURSE_MATERIAL} from "../index";
import http from "../../apis/service";
import {courseMaterialDetail} from "../../apis/endPoint";

export const getCourseMaterials = (pageNumber, items) => async dispatch => {
    const response = await http.get(courseMaterialDetail);
    dispatch({
        type: FETCH_COURSE_MATERIAL,
        payload: response.materials
    });

};